import React from "react";
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

class BarChart extends React.Component  {

  constructor(props) {
    super(props);
    this.state = {
      data1 : {
        labels: ['Activaciones'], // Etiqueta para el gráfico
        datasets: [
          {
            label: 'mes actual',
            data: [this.props?.currentMonthActivations], // Datos para el mes actual
            backgroundColor: 'rgba(255, 99, 132, 0.6)', // Color del mes actual
            borderColor: 'rgba(255, 99, 132, 1)', // Borde del mes actual
            borderWidth: 1,
          },
          {
            label: 'mes anterior',
            data: [this.props?.previousMonthActivations], // Datos para el mes anterior
            backgroundColor: 'rgba(54, 162, 235, 0.6)', // Color del mes anterior
            borderColor: 'rgba(54, 162, 235, 1)', // Borde del mes anterior
            borderWidth: 1,
          },
        ],
      },
      data2 : {
        labels: ['Registros'], // Etiqueta para el gráfico
        datasets: [
          {
            label: 'mes actual',
            data: [this.props?.currentMonthRegistrations], // Datos para el mes actual
            backgroundColor: 'rgba(255, 99, 132, 0.6)', // Color del mes actual
            borderColor: 'rgba(255, 99, 132, 1)', // Borde del mes actual
            borderWidth: 1,
          },
          {
            label: 'mes anterior',
            data: [this.props?.previousMonthRegistrations], // Datos para el mes anterior
            backgroundColor: 'rgba(54, 162, 235, 0.6)', // Color del mes anterior
            borderColor: 'rgba(54, 162, 235, 1)', // Borde del mes anterior
            borderWidth: 1,
          },
        ],
      },
      options : {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: '', // Título del gráfico
          },
          datalabels: {
            display: true, // Muestra las etiquetas
            color: 'black', // Color del texto
            anchor: 'center', // Posición relativa a la barra
            align: 'center', // Alineación respecto a la barra
            formatter: (value) => value, // Formateador (opcional)
          },
        },
        scales: {
          y: {
            beginAtZero: true, // Asegura que el eje Y empiece en 0
          },
        },
      },
      usersCurrentMonthActivations: this.props.usersCurrentMonthActivations,
      currentPageActivations: 0,
      perPageActivations: 4, // Número de elementos por página
      pageCountActivations: Math.ceil(this.props.currentMonthActivations / 4),

      usersCurrentMonthRegistrations: this.props.usersCurrentMonthRegistrations,
      currentPageRegistrations: 0,
      perPageRegistrations: 4, // Número de elementos por página
      pageCountRegistrations: Math.ceil(this.props.currentMonthRegistrations / 4)
    } 
    
    
  }

  

  render() {
    const { currentPageActivations, perPageActivations, usersCurrentMonthActivations} = this.state;
    const offsetActivations = currentPageActivations * perPageActivations;
    var pageCActivations = this.state.pageCountActivations
    var currentPageDataActivations = []
    if (usersCurrentMonthActivations.length > 0) {
        currentPageDataActivations = usersCurrentMonthActivations.slice(offsetActivations, offsetActivations + perPageActivations);
    } else {
        currentPageDataActivations = usersCurrentMonthActivations
    }

    const { currentPageRegistrations, perPageRegistrations, usersCurrentMonthRegistrations} = this.state;
    const offsetRegistrations = currentPageRegistrations * perPageRegistrations;
    var pageCRegistrations = this.state.pageCountRegistrations
    var currentPageDataRegistrations = []
    if (usersCurrentMonthRegistrations.length > 0) {
        currentPageDataRegistrations = usersCurrentMonthRegistrations.slice(offsetRegistrations, offsetRegistrations + perPageRegistrations);
    } else {
        currentPageDataRegistrations = usersCurrentMonthRegistrations
    }
    
    return (
    <div className='my-3' style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center', gap: '20px' }}>
        <div style={{ maxWidth: '600px', width: '100%', minHeight: '300px', margin: '0 10px' }}>
            <h3 style={{ textAlign: 'center' }}>Activaciones</h3>
            <Bar plugins={[ChartDataLabels]} data={this.state.data1} options={this.state.options} />

            <React.Fragment>
              <div className="row">
                <Table responsive>
                  <thead className="thead-dark">
                      <tr>
                        <th scope="col">NOMBRE</th>
                        <th scope="col">TERMINAL</th>
                        <th scope="col">SERVICIO</th>
                      </tr>
                  </thead>
                  <tbody>
                      {currentPageDataActivations.map((user, index) => (
                          <tr key={index}>
                            <td>{user.lastName + ' ' + user.secondLastName}</td>
                            <td>{user?.activationRegistrationInfo[0]?.merchantnumber}</td>
                            <td>{user?.activationRegistrationInfo[0]?.service}</td>
                          </tr>
                      ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-center">
                  <ReactPaginate
                    pageCount={pageCActivations}
                    onPageChange={this.handlePageChangeActivations}
                    containerClassName="pagination"
                    activeClassName="active"
                    forcePage={this.state.currentPageActivations}
                  />
                </div>
              </div>
            </React.Fragment>         
        </div>
        <div className='my-md-5' style={{ maxWidth: '600px', width: '100%', minHeight: '300px', margin: '0 10px' }}>            
            <h3 style={{ textAlign: 'center' }}>Registros</h3>
            <Bar plugins={[ChartDataLabels]} data={this.state.data2} options={this.state.options} />

            <React.Fragment>
              <div className="row">
                <Table responsive>
                  <thead className="thead-dark">
                      <tr>
                        <th scope="col">NOMBRE</th>
                        <th scope="col">NEGOCIO</th>
                        <th scope="col">TELEFONO</th>
                      </tr>
                  </thead>
                  <tbody>
                      {currentPageDataRegistrations.map((user, index) => (
                          <tr key={index}>
                            <td>{user.lastName + ' ' + user.secondLastName}</td>
                            <td>{user?.activationRegistrationInfo[0]?.bussinessname}</td>
                            <td>{user?.phone}</td>
                          </tr>
                      ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-center">
                  <ReactPaginate
                    pageCount={pageCRegistrations}
                    onPageChange={this.handlePageChangeRegistrations}
                    containerClassName="pagination"
                    activeClassName="active"
                    forcePage={this.state.currentPageRegistrations}
                  />
                </div>
              </div>
            </React.Fragment>  
        </div>
    </div>
  );}


  /**
  * Metodo utilizado para manejar la paginación de la tabla
  * @param {*} param0 Pagina seleccionada
  */
  handlePageChangeActivations = ({ selected }) => {
    this.setState({
        currentPageActivations: selected
        //selectedElement: null, // Reinicia el elemento seleccionado al cambiar de página
    });
  };

  handlePageChangeRegistrations = ({ selected }) => {
    this.setState({
        currentPageRegistrations: selected
        //selectedElement: null, // Reinicia el elemento seleccionado al cambiar de página
    });
  };

}

export default BarChart;
