import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import React from "react";
import Button from 'react-bootstrap/Button';
import Swal from "sweetalert2";
import '../elements/UserDashboard.css'
import axios from "axios"

/**
 * Componente que representa el modal para realizar la edición de un negocio
 */
class PayBillConfirm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showEditModal: false,
            validated: false,
            token: props.token,
            loading: false,
            pay: props.pay,
            observation: ""
        };
    }

    /**
     * Metodo utilizado para cargar información necesaria como ciudades, softwares y el token del usuario
     */
    async componentDidMount() {  }

    /**
     * Metodo encargado de desplegar u ocultar el modal y cargar la información del negocio
     * @param {*} value Si es True entonces se despliega el modal, en caso contrario se oculta
     */
    verModal = (value) => {
        this.setState({
            showEditModal: value,
            validated: false
        })
    }

    /**
     * Metodo que actualiza el valor de los atributos del estado de acuerdo a lo ingresado en el formulario
     * @param {*} e evento del input
     */
    handleChange = (e) => {
        var { name } = e.target;
        let value = e.target.type === "checkbox" ? e.target.checked : e.target.value

        this.setState(() => ({
            [name]: value
        }));

        this.valdidateForm(e)
    };

     /**
     * Metodo utilizado para validar el formulario 
     * @param {*} e Elemento que disparó el evento
     * @returns True si el formulario se encuentra correcto, False en caso contrario.
     */
     valdidateForm = (e) => {
        const form = document.getElementById("formEdit");
        this.setState({
            validated: true
        })
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();

            this.setState({
                validForm: false
            })
            return false
        } else {
            this.setState({
                validForm: true
            })
            return true
        }
    }

    /**
     * Metodo encargado de guardar el revendedor
     * @param {*} e evento submit disparado por el formulario
     */
    handleSubmitConfirm = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let data = {
            confirm: true,
            observation: this.state.observation
        }
    
        this.confirmPayment(data)
    }

    /**
     * Metodo encargado de guardar el revendedor
     * @param {*} e evento submit disparado por el formulario
     */
    handleSubmitReject = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let data = {
            confirm: false,
            observation: this.state.observation
        }

        this.confirmPayment(data)
    }

    confirmPayment(data){
        const config = {
            method: 'put',
            url: global.URLBack + `/dashBoard/superUser/invoice/${this.state.pay.invoiceNumber}/confirmOrReject`,
            headers: {
                'Authorization': 'Bearer ' + this.state.token,
                "Content-Type": "application/json",
            },
            data: data,
            withCredentials: true
        }

        this.setState({ loading: true })
        axios(config).then(response => {
            this.setState({ loading: false })

            Swal.fire({
                title: data.confirm ? "Se confirmo el pago correctamente." : "Se rechazo el pago correctamente.",
                icon: data.confirm ? "success" : "warning"
            })
            this.props.updateList(response.data)
            this.reset()
            this.verModal(false)

        }).catch((err) => {
            this.setState({ loading: false })

            Swal.fire({
                title: "Ha ocurrido un error al confirmar el pago",
                icon: "error"
            })
        })
    }


    reset() {
        this.setState({
            showEditModal: false,
            validated: false,
            token: "",
            loading: false,
            observation: ""
        })
    }

    /**
     * Metodo de renderizado
     * @returns html que contiene el botón que despliega el modal y el modal
     */
    render() {
        return (
            <React.Fragment>
                <Button variant="outline-warning" onClick={() => this.verModal(true)}>
                    Validar
                </Button>
                <Modal size='lg' show={this.state.showEditModal} onHide={() => this.verModal(false)}  >
                    {this.state.loading ?
                        <div className="spinner"></div>
                        :
                        <Form noValidate validated={this.state.validated} id="formEdit" onSubmit={this.handleSubmitEditModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Validar pago pendiente</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            <React.Fragment>
                                <div className="row">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Referencia *</Form.Label>
                                        <Form.Control type="text" value={this.state.observation} required name="observation" onChange={this.handleChange} />
                                        <Form.Control.Feedback type="invalid" >
                                            Por favor ingrese la referencia
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                
                                </div>
                            </React.Fragment>
                            </Modal.Body>
                            <Modal.Footer>

                                <Button variant="outline-success" onClick={this.handleSubmitConfirm} disabled={!this.state.validForm} >Confirmar</Button>
                                <Button variant="outline-danger" onClick={this.handleSubmitReject} >Rechazar</Button>
                                {
                                    /*
                                    <Button variant="secondary" onClick={() => this.verModal(false)}>
                                        Volver
                                    </Button>
                                    */
                                }
                                
                            </Modal.Footer>
                        </Form>
                    }
                </Modal>
            </React.Fragment>
            
        )
    }

}

export default PayBillConfirm;