import React from "react";
import "../styles.css";
import {
  Formn,
  Label,
  Input,
  ContainerTerms,
  ContainerButtonCA,
  Buttonn,
  MsjFail,
  SelectPM,
  SelectT,
  SelectSW,
  InputState,
  SelectService,
  SelectPhysiTer,
  Selectll,
  LabelCIvu,
  Titulo,
  InputZip,
} from "../elements/Forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faShoppingBasket,
  faTachometerAlt,
} from "@fortawesome/free-solid-svg-icons";
import Navbar from "../componentes/Navbar";
import "../styles.css";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import "../componentes/GlobalVariables";
import axios from "axios";
import InputMask from "react-input-mask";
import QR from "../img/athm_qrcode_sticker.png";
import { withRouter } from 'react-router-dom';
import { left } from "@popperjs/core";

/**
 * Componente encargado de registrar un nuevo usuario
 */
class Register extends React.Component {
  /**
   * Función constructora para la clase.
   *
   * @param props - El objeto de props.
   */
  constructor(props) {
    super(props);
    this.state = {
      data: {
        userName: "",
        lastName: "",
        secondlastname: "",
        email: "",
        password: "",
        password2: "",
        bussinessname: "",
        merchantnumber: "",
        mobilephone: "",
        phone: "",
        address: "",
        address2: "",
        state: "PR",
        zipcode: "",
        nameoncard: "",
        securitycode: "",
        creditcarnumber: "",
        terms: null,
        town: 1,
        service: "",
        software: "Retail Manager",
        fiscalterminals: 1,
        paymethod: "CREDIT-CARD",
        cardtype: "Visa",
        expDateM: "",
        expDateY: "",
        routeNumber: "",
        accountNumber: "",
        accountNumber2: "",
        accountNameBank: "",
        accountNumberBank: "",
        routeNumberBank: "",
        idReseller: -1
      },
      sc: "",
      mc: "",
      res: {
        typeCard: [
          {
            id: 1,
            value: "Visa",
          },
        ],
        service: [
          {
            id: 1,
            value: "Ivu Basic[15.00/mes]",
          },
        ],
        year: null,
        towns: [
          {
            id: 1,
            value: "Adjuntas",
          },
        ],
        software: [
          {
            id: 1,
            value: "Retail Manager",
          },
        ],
        numTerm: [
          {
            id: 1,
            value: "20",
          },
        ],
      },
      valid: null,
      validForm: null,
      //validUpdate: null,
      error: "",
      loading: null,
      formSC: "",
      formMC: "",
      expressiones: {
        name: /^[a-zA-ZÀ-ÿ\s]{3,40}$/, // Letras y espacios, pueden llevar acentos.
        email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        password: /^.{4,30}$/, // 4 a 12 digitos.
        user: /^[a-zA-Z0-9_-]+[a-zA-ZÀ-ÿ\s]{4,16}$/, // Letras, numeros, guion y guion_bajo

        merchantNumber: /^\d{11}$/,

        nameCard: /^[a-zA-ZÀ-ÿ\s]{1,40}$/,
        nameCount: /^.+/,
        securityCode: /^\d{3}$/,
        securityCode2: /^\d{4}$/,
        zipCode: /^\d{5}$/,

        state: /^[a-zA-ZÀ-ÿ\s]{3,10}$/,
        routeNumber: /^\d{4,9}$/,
        accountNumber: /^\d{4,16}$/,
      },
      paymentMethods: [],
      resellers: [],
      linkReseller: false,
      imageReseller: "",
      valuesAdditionalTerminal: {},
      totalAmount: 0,
      additionalTerminal: 0,
      mask: "9999-9999-9999-9999",
      maskCvv2: "999"
    };
  }

  /**
   * Método del ciclo de vida del componente que se llama después de que el componente se haya renderizado en el DOM.
   * Obtiene datos adicionales de terminales, recupera información del revendedor, carga datos y establece el estado en consecuencia.
   * Si se proporciona un nombre de usuario, también recupera el perfil de imagen del revendedor.
   *
   */
  componentDidMount() {
    const { username } = this.props.match.params;
    const serviceHome = this.props.location?.state?.serviceV;
    const aditionalTerminalsConfig = {
      method: "get",
      url: global.URLBack + `/query/register/aditionalTerminals`,
    };

    axios(aditionalTerminalsConfig).then(response => {
      if (Number(response.status) === 200) {
        this.setState({ valuesAdditionalTerminal: response.data })
      }
    }).catch(err => {

    })

    this.loadImageReseller(username)

    const config = {
      method: "get",
      url: global.URLBack + `/query/register/loadData/3`,
    };

    axios(config)
      .then((res) => {
        this.setState({
          res: res.data,
        }, () => {
          /**
           * Se realizó está modificación con respecto a la primer versión debido a que de esta manera
           * listan los servicios activos 
           */
          const config2 = {
            method: 'get',
            url: global.URLBack + `/dashBoard/service`
          }

          axios(config2)
            .then((response) => {
              let s = response.data[0].serviceName
              if (serviceHome !== undefined) {
                s = serviceHome
              }
              this.setState((prevState) => ({
                res: { ...prevState.res, service: response.data },
                data: { ...prevState.data, service: s, software: res.data.software[0].value }
              }), () => {
                let totalAmount = Number(this.getService(this.state.data.service)) + Number(this.state.additionalTerminal)
                this.setState({ totalAmount: totalAmount })
              });
              const config3 = {
                method: 'get',
                url: global.URLBack + `/dashBoard/superUser/paymentMethods`,
              }

              axios(config3).then(response2 => {
                this.setState({ paymentMethods: response2.data })

                const config4 = {
                  method: 'get',
                  url: global.URLBack + `/dashBoard/reseller`,
                }

                axios(config4).then(response3 => {
                  if (Number(response3.status) === 200) {
                    this.setState({ resellers: response3.data })
                  }
                }).catch(err2 => {

                })
              }).catch(error => {
              })
            }).catch(err => {
            })
        });
        /* this.setState({
          data: {
            ...this.state.data,
            service: res.data.service[0].value,
            software: res.data.software[0].value,
          },
        }); */
      })
      .catch((err) => { });



  }

  loadImageReseller(username){
    if (username != undefined) {

      const configReseller = {
        method: "get",
        url: global.URLBack + `/dashBoard/reseller/findByUsername/${username}`,
      };
      axios(configReseller).then(res => {
        if (Number(res.status) === 200) {

          this.setState({
            data: {
              ...this.state.data,
              idReseller: res.data.id
            },
            linkReseller: true
          })

          const config3 = {
            method: 'get',
            url: global.URLBack + `/dashBoard/reseller/imageprofile/${res.data.imageprofile}`,
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
            responseType: 'arraybuffer',
          }

          axios(config3).then(response => {

            // Crear un objeto Blob a partir de los bytes de la imagen
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            // Crear una URL que apunte al Blob
            const imageUrl = URL.createObjectURL(blob);

            this.setState({
              imageReseller: imageUrl
            })
          }).catch(error => {

          })
        }
      })
    } else {
      this.setState({
        linkReseller: false
      })
    }
  }

  /**
   * Recupera el valor del servicio para un nombre de servicio dado.
   *
   * @param {string} serviceName - El nombre del servicio a recuperar.
   * @return {Array} Un array de valores de servicio que coinciden con el nombre de servicio dado.
   */
  getService(serviceName) {
    return this.state.res.service.filter(service => service.serviceName == serviceName).map(service => service.serviceValue)
  }
  /*componentDidUpdate(pp,prevState,ss){
    if( this.state.data.paymethod == "ATHMOVIL"){
    const paymentData = document.createElement('script');
      let data = document.createTextNode(`ATHM_Checkout = {
        env: 'sandbox',
        publicToken: 'sandboxtoken01875617264',
        timeout: 500,
        theme: 'btn',
        lang: 'en',
        total: '${this.state.data.service == "Ivu Basic[15.00/mount]" ? 15.00 * parseInt(this.state.data.fiscalterminals) : 99.00 * parseInt(this.state.data.fiscalterminals)}',
        tax: 0.00,
        subtotal: 0.00,
        metadata1: 'test',
        metadata2: 'metadata2 test',
        items: [
          {
            "name": "${this.state.data.service}",
            "description": "Número de terminales fiscales: ${this.state.data.fiscalterminals}",
            "price": "${this.state.data.service == "Ivu Basic[15.00/mount]" ? 15.00 * parseInt(this.state.data.fiscalterminals) : 99.00 * parseInt(this.state.data.fiscalterminals)}",
            "metadata": "metadata test"
          }
      ],
      onCancelledPayment: function (response) {
        // console.log('onCancelledPayment', response)
        // window.canPay = response
      },
      onExpiredPayment: function (response) {
        // console.log('onExpiredPayment', response)
        // window.expirePay = response
      }
    }`
    )
    paymentData.async = true;
    paymentData.appendChild(data);
    const script = document.createElement('script');
    const jQueryData = document.createElement('script');
    script.src = `https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js`;
    jQueryData.src = `https://www.athmovil.com/api/js/v2/athmovilV2.js`;
    script.async = true;
    jQueryData.async = true;
    document.body.appendChild(script);
    document.body.appendChild(jQueryData);
    document.body.appendChild(paymentData);

    if(prevState.data !== this.state.data){
      console.log("hola")
    if (
      this.state.data.userName === "" ||
      !this.state.expressiones.name.test(this.state.data.userName)
    ) {
      this.setState({
        valid: false,
        error: "El nombre debe tener mínimo 3 letras",
      });

      return this.setState({validUpdate: false});
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.data.lastName === "" ||
      !this.state.expressiones.name.test(this.state.data.lastName)
    ) {
      this.setState({
        valid: false,
        error: "El primer apellido debe tener mínimo de 3 letras",
      });
      return this.setState({validUpdate: false});
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.data.secondlastname === "" ||
      !this.state.expressiones.name.test(this.state.data.secondlastname)
    ) {
      this.setState({
        valid: false,
        error: "El segundo apellido debe tener mínimo 3 letras",
      });
      return this.setState({validUpdate: false});
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.data.email === "" ||
      !this.state.expressiones.email.test(this.state.data.email)
    ) {
      this.setState({
        valid: false,
        error: "El correo es inválido",
      });
      return this.setState({validUpdate: false});
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.data.password === "" ||
      !this.state.expressiones.password.test(this.state.data.password)
    ) {
      this.setState({
        valid: false,
        error: "La contraseña tiene que ser de mínimo 4 caracteres.",
      });
      return this.setState({validUpdate: false});
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.data.password2 !== this.state.data.password ||
      !this.state.expressiones.password.test(this.state.data.password2)
    ) {
      this.setState({
        valid: false,
        error: "Ambas contraseñas deben ser iguales.",
      });
      return this.setState({validUpdate: false});
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (this.state.data.bussinessname === "") {
      this.setState({
        valid: false,
        error: "El nombre del negocio debe ser de mínimno 4 caracteres.",
      });
      return this.setState({validUpdate: false});
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.data.merchantnumber === "" ||
      !this.state.expressiones.merchantNumber.test(
        this.state.data.merchantnumber
      )
    ) {
      this.setState({
        valid: false,
        error:
          "El número de registro de comerciante debe ser máximo de 11 dígitos.",
      });
      return this.setState({validUpdate: false});
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (this.state.data.mobilephone === "" && this.state.data.phone === "") {
      this.setState({
        valid: false,
        error: "Debe insertar un número de celular o teléfono",
      });
      return this.setState({validUpdate: false});
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    // if (this.state.data.phone === "") {
      this.setState({
        valid: false,
        error: "El número de teléfono debe ser de 10 dígitos.",
      });
      return this.setState({validUpdate: false});
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    //} 
    if (this.state.data.address === "") {
      this.setState({
        valid: false,
        error: "La dirección no es válida",
      });
      return this.setState({validUpdate: false});
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
   //  if (this.state.data.address2 === "") {
      this.setState({
        valid: false,
        error: "La dirección 2 no es válida",
      });
      return this.setState({validUpdate: false});
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    //} 

    if (
      this.state.data.zipcode === "" ||
      !this.state.expressiones.zipCode.test(this.state.data.zipcode)
    ) {
      this.setState({
        valid: false,
        error: "Debe ingresar un código postal de 5 dígitos.",
      });
      return this.setState({validUpdate: false});
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.state === "" ||
      !this.state.expressiones.state.test(this.state.state)
    ) {
      this.setState({
        valid: false,
        error: "Debe ingresar un estado válido",
      });
      return this.setState({validUpdate: false});
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (this.state.data.paymethod === "CREDIT-CARD") {
      if (
        this.state.data.nameoncard === "" ||
        !this.state.expressiones.nameCard.test(this.state.data.nameoncard)
      ) {
        this.setState({
          valid: false,
          error: "El nombre en la tarjeta debe tener de 10 a 40 letras",
        });
        return this.setState({validUpdate: false});
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (
        this.state.data.securitycode === "" ||
        !this.state.expressiones.securityCode.test(this.state.data.securitycode)
      ) {
        this.setState({
          valid: false,
          error:
            "El código de seguridad solo puede contener un máximo de 3 dígitos.",
        });
        return this.setState({validUpdate: false});
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (this.state.data.creditcarnumber === "") {
        this.setState({
          valid: false,
          error: "Debe ingresar un número de tarjeta válido.",
        });

        return this.setState({validUpdate: false});
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (
        this.state.data.expDateM === "" ||
        (parseInt(this.state.res.month) > parseInt(this.state.data.expDateM) &&
          parseInt(this.state.res.year) >=
            parseInt(this.state.data.expDateY)) ||
        this.state.data.expDateM > "12"
      ) {
        this.setState({
          valid: false,
          error: "Debe ingresar una fecha de expiración válida.",
        });

        return this.setState({validUpdate: false});
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (
        this.state.data.expDateY === "" ||
        parseInt(this.state.res.year) > parseInt(this.state.data.expDateY) ||
        parseInt(this.state.data.expDateY) > parseInt(this.state.res.year) + 46
      ) {
        this.setState({
          valid: false,
          error: "Debe ingresar una fecha de expiración válida.",
        });

        return this.setState({validUpdate: false});
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
    } else if (this.state.data.paymethod === "BANK-ACCOUNT") {
      if (
        this.state.data.accountNameBank === "" ||
        !this.state.expressiones.nameCard.test(this.state.data.accountNameBank)
      ) {
        this.setState({
          valid: false,
          error: "El nombre en la tarjeta debe tener de 10 a 40 letras",
        });
        return this.setState({validUpdate: false});
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (
        this.state.data.accountNumberBank === "" ||
        !this.state.expressiones.accountNumber.test(
          this.state.data.accountNumberBank
        )
      ) {
        this.setState({
          valid: false,
          error: "Debe ingresar un número de cuenta válido.",
        });
        return this.setState({validUpdate: false});
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (
        this.state.data.routeNumberBank === "" ||
        !this.state.expressiones.routeNumber.test(
          this.state.data.routeNumberBank
        )
      ) {
        this.setState({
          valid: false,
          error: "Debe ingresar un número de ruta válido",
        });
        return this.setState({validUpdate: false});
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
    }

    if (!this.state.data.terms) {
      this.setState({
        valid: false,
        error: "Aceptar términos y condiciones",
      });
      return this.setState({validUpdate: false});
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    return this.setState({validUpdate: true});
  }
}
}*/

  /**
   * Metodo encargado de validar los campos del formulario
   * @returns True si todos los campos se encuentran correctos, False en caso contrario
   */
  validation = () => {
    if (
      this.state.data.userName === "" // ||
      //!this.state.expressiones.name.test(this.state.data.userName)
    ) {
      this.setState({
        valid: false,
        error: "Por favor escriba un nombre",
      });

      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.data.lastName === "" //||
      //!this.state.expressiones.name.test(this.state.data.lastName)
    ) {
      this.setState({
        valid: false,
        error: "Por favor escriba un apellido",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.data.secondlastname === "" ||
      !this.state.expressiones.name.test(this.state.data.secondlastname)
    ) {
      this.setState({
        valid: false,
        error: "El segundo apellido debe tener mínimo 3 letras",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.data.email === "" ||
      !this.state.expressiones.email.test(this.state.data.email)
    ) {
      this.setState({
        valid: false,
        error: "El correo es inválido",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.data.password === "" ||
      !this.state.expressiones.password.test(this.state.data.password)
    ) {
      this.setState({
        valid: false,
        error: "La contraseña tiene que ser de mínimo 4 caracteres.",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.data.password2 !== this.state.data.password ||
      !this.state.expressiones.password.test(this.state.data.password2)
    ) {
      this.setState({
        valid: false,
        error: "Ambas contraseñas deben ser iguales.",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (this.state.data.bussinessname === "") {
      this.setState({
        valid: false,
        error: "El nombre del negocio debe ser de mínimno 4 caracteres.",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.data.merchantnumber === "" ||
      !this.state.expressiones.merchantNumber.test(
        this.state.data.merchantnumber
      )
    ) {
      this.setState({
        valid: false,
        error:
          "El número de registro de comerciante debe ser máximo de 11 dígitos.",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (this.state.mc === "") {
      this.setState({
        valid: false,
        error: "Debe insertar un certificado de registro de comerciante",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (this.state.data.mobilephone === "" && this.state.data.phone === "") {
      this.setState({
        valid: false,
        error: "Debe insertar un número de celular o teléfono",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    /* if (this.state.data.phone === "") {
      this.setState({
        valid: false,
        error: "El número de teléfono debe ser de 10 dígitos.",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    } */
    if (this.state.data.address === "") {
      this.setState({
        valid: false,
        error: "La dirección no es válida",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    /*  if (this.state.data.address2 === "") {
      this.setState({
        valid: false,
        error: "La dirección 2 no es válida",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    } */

    if (
      this.state.data.zipcode === "" ||
      !this.state.expressiones.zipCode.test(this.state.data.zipcode)
    ) {
      this.setState({
        valid: false,
        error: "Debe ingresar un código postal de 5 dígitos.",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.state === "" ||
      !this.state.expressiones.state.test(this.state.state)
    ) {
      this.setState({
        valid: false,
        error: "Debe ingresar un estado válido",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (this.state.data.paymethod === "CREDIT-CARD") {
      if (
        this.state.data.nameoncard === "" ||
        !this.state.expressiones.nameCard.test(this.state.data.nameoncard)
      ) {
        this.setState({
          valid: false,
          error: "El nombre en la tarjeta máximo de 40 letras",
        });
        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (
        this.state.data.securitycode === "" ||
        (this.state.data.creditcarnumber.substring(0, 1) != "3" && !this.state.expressiones.securityCode.test(this.state.data.securitycode))
      ) {
        this.setState({
          valid: false,
          error:
            "El código de seguridad solo puede contener un máximo de 3 dígitos.",
        });
        return false;
      } else  if (
        this.state.data.securitycode === "" ||
        (this.state.data.creditcarnumber.substring(0, 1) == "3" && !this.state.expressiones.securityCode2.test(this.state.data.securitycode))
      ) {
        this.setState({
          valid: false,
          error:
            "El código de seguridad solo puede contener un máximo de 4 dígitos.",
        });
        return false;
      }
      else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (this.state.data.creditcarnumber === "") {
        this.setState({
          valid: false,
          error: "Debe ingresar un número de tarjeta válido.",
        });

        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (
        this.state.data.expDateM === "" ||
        (parseInt(this.state.res.month) > parseInt(this.state.data.expDateM) &&
          parseInt(this.state.res.year) >=
          parseInt(this.state.data.expDateY)) ||
        this.state.data.expDateM > "12"
      ) {
        this.setState({
          valid: false,
          error: "Debe ingresar una fecha de expiración válida.",
        });

        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (
        this.state.data.expDateY === "" ||
        parseInt(this.state.res.year) > parseInt(this.state.data.expDateY) ||
        parseInt(this.state.data.expDateY) > parseInt(this.state.res.year) + 46
      ) {
        this.setState({
          valid: false,
          error: "Debe ingresar una fecha de expiración válida.",
        });

        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
    } else if (this.state.data.paymethod === "BANK-ACCOUNT") {
      if (
        this.state.data.accountNameBank === "" ||
        !this.state.expressiones.nameCount.test(this.state.data.accountNameBank)
      ) {
        this.setState({
          valid: false,
          error: "El nombre en la tarjeta debe tener de 10 a 40 letras",
        });
        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (
        this.state.data.accountNumberBank === "" ||
        !this.state.expressiones.accountNumber.test(
          this.state.data.accountNumberBank
        )
      ) {
        this.setState({
          valid: false,
          error: "Debe ingresar un número de cuenta válido.",
        });
        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (
        this.state.data.routeNumberBank === "" ||
        !this.state.expressiones.routeNumber.test(
          this.state.data.routeNumberBank
        )
      ) {
        this.setState({
          valid: false,
          error: "Debe ingresar un número de ruta válido",
        });
        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
    }

    if (!this.state.data.terms) {
      this.setState({
        valid: false,
        error: "Aceptar términos y condiciones",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    return true;
  };

  /**
   * Metodo encargado de registrar un nuevo usuario
   * @param {*} e evento disparado por el formulario
   */
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validation()) {
      this.setState({ validForm: true, loading: true });
      const config = {
        method: "post",
        url: global.URLBack + `/query/register/newRegister`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(this.state.data),
        withCredentials: true,
      };
      const formData = new FormData();
      formData.append("SC", this.state.sc);
      formData.append("MC", this.state.mc);
      axios(config)
        .then((res) => {
          const files = {
            method: "post",
            url:
              global.URLBack +
              `/query/register/saveForms/` +
              res.data.idUser + '/' +
              res.data.idAddress,
            headers: {
              "Content-Type": "multipart/form-data",
            },
            data: formData,
            withCredentials: true,
          };
          axios(files)
            .then((res) => {
              this.setState({
                loading: false
              })

              this.props.history.push("/register/new/accountCreated");
            })
            .catch((err) => {

              this.setState({
                loading: false,
                validForm: false,
                error: "Ha ocurrido un error en el registro, inténtelo de nuevo"
              })
            });
        })
        .catch((err) => {
          if (err.response.data.msg) {
            this.setState({
              loading: false,
              validForm: false,
              error: err.response.data.msg,
            });
          } else {
            this.setState({
              loading: false,
              validForm: false,
              error: "Ha ocurrido un error en el registro, inténtelo de nuevo",
            });
          }
        });
    } else {
      this.setState({ validForm: false });
    }
  };

  /*handleButton = (e) => {
    e.preventDefault();
    window.ATHM_Checkout.onCompletedPayment = (response) =>
    {       
      const config = {
        method: "post",
        url: global.URLBack + `/query/register/newRegister`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(this.state.data),
        withCredentials: true,
      };

      axios(config)
        .then((res) => {
          this.props.history.push("/register/accountCreated");
        })
        .catch((err) => {
          if (err.response.data.msg) {
            this.setState({
              loading: false,
              validForm: false,
              error: err.response.data.msg,
            });
          } else {
            this.setState({
              loading: false,
              validForm: false,
              error: "Ha ocurrido un error en el registro, inténtelo de nuevo",
            });
          }
        });
    };
    window.ATHM_Checkout.onCancelledPayment = (response) => {
       console.log('onCancelledPayment', response)
      // window.canPay = response
    };
    window.ATHM_Checkout.onExpiredPayment= (response) => {
      // console.log('onExpiredPayment', response)
      // window.expirePay = response
    }
  }

*/
  /**
   * Metodo encargado de actualizar los atributos de data
   * @param {*} e evento disparado por los input
   */
  handleChange = (e) => {
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: value,
      },
    });

    if (e.target.name === "merchantnumber") {
      const config = {
        method: "get",
        url:
          global.URLBack +
          `/query/register/checkMerchantNumber/` +
          e.target.value,
        headers: {
          "Content-Type": "text/plain",
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.res === false) {
            this.setState({
              validForm: false,
              error: "Número de registro de comerciante existente",
            });
          } else {
            this.setState({
              validForm: null,
              error: "",
            });
          }
        })
        .catch((err) => { });
    }
    if (e.target.name === "email") {
      const config = {
        method: "get",
        url: global.URLBack + `/query/register/checkEmail/` + e.target.value,
        headers: {
          "Content-Type": "text/plain",
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.res === false) {
            this.setState({
              validForm: false,
              error: "Correo electrónico existente",
            });
          } else {
            this.setState({
              validForm: null,
              error: "",
            });
          }
        })
        .catch((err) => { });
    }
    if (e.target.name === "creditcarnumber") { 
      switch (e.target.value.substring(0, 1)) {
        case "6":
          this.setState({
            data: {
              ...this.state.data,
              cardtype: "Discover",
              creditcarnumber: e.target.value,
            },
          })
          this.setState({ mask: "9999-9999-9999-9999", maskCvv2: "999" });
          break;
        case "5":
          this.setState({
            data: {
              ...this.state.data,
              cardtype: "Master Card",
              creditcarnumber: e.target.value,
            },
          })
          this.setState({ mask: "9999-9999-9999-9999", maskCvv2: "999" })
          break;
        case "4":
          this.setState({
            data: {
              ...this.state.data,
              cardtype: "Visa",
              creditcarnumber: e.target.value,
            },
          })
          this.setState({ mask: "9999-9999-9999-9999", maskCvv2 : "999" })
          break;
        case "3":
          this.setState({
            data: {
              ...this.state.data,
              cardtype: "American Express",
              creditcarnumber: e.target.value,
            },
          })
          this.setState({ mask: "9999-999999-99999", maskCvv2: "9999" })
          break;
        default:
      }
    }

    if (e.target.name === "fiscalterminals") {
      let valueAdditionalTerminal = 0
      if (Number(e.target.value) > 1 && Number(e.target.value) <= 5) {
        valueAdditionalTerminal = (Number(e.target.value) - 1) * Number(this.state.valuesAdditionalTerminal.terminals2to5)
      } else if (Number(e.target.value) > 5 && Number(e.target.value) <= 9) {
        valueAdditionalTerminal = (Number(e.target.value) - 1) * Number(this.state.valuesAdditionalTerminal.terminals6to9)
      } else if (Number(e.target.value) >= 10) {
        valueAdditionalTerminal = (Number(e.target.value) - 1) * Number(this.state.valuesAdditionalTerminal.terminals10)
      }

      let totalAmount = Number(this.getService(this.state.data.service)) + Number(valueAdditionalTerminal)

      this.setState({ additionalTerminal: valueAdditionalTerminal, totalAmount: totalAmount })
    }

    if (e.target.name === "service") {
      let totalAmount = Number(this.getService(e.target.value)) + Number(this.state.additionalTerminal)
      this.setState({ totalAmount: totalAmount })
    }

  };

  /**
   * Metodo encargado de guardar en una variable los archivos subidos por el usuario
   * @param {*} e evento disparado por el input
   */
  handleFiles = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0]
    })
  }

  handleChangeCardNumber = () => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        securitycode: '', 
      },
    }))
  };
  

  /**
   * Renderiza el componente.
   *
   * @return  El componente renderizado.
   */
  render() {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, i) => currentYear + i); // Opciones de años próximos

    return (
      <div>
        <header>
          <Navbar />
        </header>

        <main>
          {/* {this.state.loading ? <div className="spinner"></div> : <div className="spinner"></div>} */}

          <div className="son2">
            <div>
              <Titulo>Registrarse</Titulo>
            </div>
            <div>
              <Breadcrumbs
                className="breadcrumbs"
                separator=">"
                aria-label="breadcrumb"
              >
                <Link className="link-rp" color="inherit" to="/">
                  <FontAwesomeIcon className="icon" icon={faTachometerAlt} />
                  Panel
                </Link>
                <Link className="link-rp" color="inherit" to="/register">
                  Registrarse
                </Link>
              </Breadcrumbs>
            </div>
          </div>
          <div className="son1">
            <LabelCIvu>Creación de cuenta IvuControlPR</LabelCIvu>
          </div>
          <div className={this.state.loading ? "son-hidden" : "son"}>
            <Formn action="" onSubmit={this.handleSubmit} className={this.state.linkReseller ? "mt-5" : null}>
              <Label>
                Nombre de usuario *
                <Input
                  onChange={this.handleChange}
                  name="userName"
                  type="text"
                  placeholder="Nombre"
                />
              </Label>
              <Label>
                Primer Apellido *
                <Input
                  onChange={this.handleChange}
                  name="lastName"
                  type="text"
                  placeholder="Primer Apellido"
                />
              </Label>
              <Label>
                Segundo Apellido *
                <Input
                  onChange={this.handleChange}
                  name="secondlastname"
                  type="text"
                  placeholder="Segundo Apellido"
                />
              </Label>
              <Label>
                Correo Electrónico *
                <Input
                  onChange={this.handleChange}
                  name="email"
                  type="text"
                  placeholder="Correo Electrónico"
                />
              </Label>
              <Label>
                Contraseña *
                <Input
                  onChange={this.handleChange}
                  name="password"
                  type="password"
                  placeholder="Contraseña"
                />
              </Label>

              <Label>
                Confirmar Contraseña *
                <Input
                  onChange={this.handleChange}
                  name="password2"
                  type="password"
                  placeholder="Confirmar Contraseña"
                />
              </Label>
              <Label>
                Nombre del Negocio *
                <Input
                  onChange={this.handleChange}
                  name="bussinessname"
                  type="text"
                  placeholder="Nombre del Negocio"
                />
              </Label>
              <Label>
                Número de registro del Comerciante *
                <InputMask
                  className="inputMask"
                  onChange={this.handleChange}
                  name="merchantnumber"
                  type="text"
                  mask="99999999999"
                  placeholder="Registro del Comerciante"
                />
              </Label>
              <Label>
                Formulario SC2916
                <span>
                  <div className="hhh">
                    <label className="sel">
                      <input className="fales"
                        type="file"
                        id="file1"
                        name="sc"
                        placeholder="Seleccionar archivo"
                        accept="application/pdf"
                        onChange={this.handleFiles}
                      />

                      seleccionar Archivo

                    </label><div className="file-preview">{this.state.sc !== undefined ? this.state.sc.name : ""} </div>
                  </div>
                </span>


                {/*this.state.formSC ? <div>
                            <label><b>Archivo existente</b></label>
                            <p>Si desea actualizar la información presione "seleccionar archivo"</p>
                            <div className="div-form-bi">
                            
                            <input type="file" id="file" name="sc" placeholder="Seleccionar archivo"
                            onChange={this.handleOnFileChange}/>
                        </div>
                            <div className="row">

                              {this.state.sc ?
                            <div className="col-5">
                            <button id="btn1-bi" className="btn btn-light" type="button"
                            onClick={this
                              .submitUpdateSC
                              .bind(this)}>Actualizar formulario SC2916</button>
                              </div>
                              : null}
                            <div className="col-6">
                            <button id="btn1-bi" className="btn btn-light" 
                            onClick={this.handleDownloadSC.bind(this)}
                        >Visualizar formulario SC2916</button>
                            </div>
                            </div>
                          </div>
                          : <div>
                            <div className="div-form-bi">                            
                            <input type="file" id="file" name="sc" placeholder="Seleccionar archivo"
                            onChange={this.handleOnFileChange}/>
                        </div>
                        <div className="row">
                        {this.state.sc ? <div className="col-5">
                        <button id="btn1-bi" className="btn btn-light" type="button"
                        onClick={this
                          .submitLogin
                          .bind(this)}>Subir formulario SC2916</button>

                        </div> : null }
                        
                          
                          </div>
                        </div>*/}
              </Label>
              <Label>
                Certificado de Registro de Comerciante *
                <span>
                  <div className="hhh">
                    <label className="sel">
                      <input className="fales"
                        type="file"
                        id="file2"
                        name="mc"
                        placeholder="Seleccionar archivo"
                        accept="application/pdf"
                        onChange={this.handleFiles}
                      /> seleccionar Archivo {" "}
                    </label> <div className="file-preview"> {this.state.mc !== undefined ? this.state.mc.name : ""}</div>
                  </div>
                </span>
                {/*

                this.state.formMC ?<div> <label><b>Archivo existente</b></label>
                          <p>Si desea actualizar la Información presione "seleccionar archivo"</p>
                            <div className="div-form-bi">
                            <input type="file" id="file" name="mc" placeholder="Seleccionar archivo"
                            onChange={this.handleChange}/> </div> <div className="row">
                              {this.state.mc ? 
                          <div className="col-6">

                        <button id="btn2-bi" className="btn btn-light" type="button"
                        onClick={this
                          .submitUpdateMC
                          .bind(this)}>Actualizar Certificado de Registro de Comerciante</button>
                          </div>
                        : null}
                           <div className="col-6">
                          <button id="btn2-bi" className="btn btn-light" type="button"
                          onClick={this.handleDownloadMC.bind(this)}
                        >Visualizar Registro de Comerciante</button>
                        </div>
                          
                        </div></div>: <div> <div className="div-form-bi">                         
                            <input type="file" id="file2" name="mc" placeholder="Seleccionar archivo"
                            onChange={this.handleOnFileChange}/>
                        </div>
                        <div className="row">
                          {this.state.mc ? <div className="col-7">

<button id="btn2-bi" className="btn btn-light" type="button"
onClick={this
  .submitLoginMC
  .bind(this)}>Subir Certificado de Registro de Comerciante</button>
  </div> : null }
                          
</div> </div>*/}
              </Label>
              <Label>
                Teléfono móvil *
                <InputMask
                  className="inputMask"
                  onChange={this.handleChange}
                  name="mobilephone"
                  type="text"
                  mask="999-999-9999"
                  placeholder="Número de teléfono móvil"
                />
              </Label>
              <Label>
                Teléfono
                <InputMask
                  className="inputMask"
                  onChange={this.handleChange}
                  name="phone"
                  type="text"
                  mask="999-999-9999"
                  placeholder="Número de teléfono"
                />
              </Label>
              <Label>
                Dirección *
                <Input
                  onChange={this.handleChange}
                  name="address"
                  type="text"
                  placeholder="Dirección"
                />
              </Label>

              <Label>
                Linea de Dirección 2
                <Input
                  onChange={this.handleChange}
                  name="address2"
                  type="text"
                  placeholder="Dirección"
                />
              </Label>
              <Label>
                Ciudad / Estado / Código Postal *
                <div className="DivForm">
                  <SelectT onChange={this.handleChange} name="town" className="form-control" >
                    {this.state.res.towns.map((town) => (
                      <option key={town.id} value={town.id}>
                        {town.value}
                      </option>
                    ))}
                  </SelectT>

                  <InputState
                    onChange={this.handleChange}
                    placeholder="Estado"
                    name="state"
                    //type="text"
                    value="PR"
                    disabled
                  />

                  <InputZip
                    onChange={this.handleChange}
                    placeholder="Código Postal"
                    type="text"
                    name="zipcode"
                  />
                </div>
              </Label>
              <Label>
                Servicio / Terminales Físicas *
                <div>
                  <FontAwesomeIcon icon={faShoppingBasket}></FontAwesomeIcon>

                  <SelectService onChange={this.handleChange} name="service" value={this.state.data.service}>
                    {this.state.res.service.filter(s => s.active === true).map((s) => (
                      <option key={s.serviceId} value={s.serviceName}>
                        {s.serviceName}
                      </option>
                    ))}
                  </SelectService>
                  <SelectPhysiTer
                    onChange={this.handleChange}
                    name="fiscalterminals"
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
                  </SelectPhysiTer>
                </div>
              </Label>


              <Label>
                Método de pago *
                <SelectPM onChange={this.handleChange} name="paymethod" value={this.state.data.paymethod}>
                  {this.state.paymentMethods.filter(method => method.status === true).map((method, index) => (
                    <option key={index} value={method.code}>{method.name}</option>
                  ))
                  }
                </SelectPM>
                {this.state.data.paymethod === "ATHMOVIL" ? (
                  <div id="form-group-ath-movil">
                    <label className="QR_text">
                      Escanea el código QR para proceder con tu pago.
                    </label>
                    <label>
                      <img width="400vw" height="550vh" src={QR} alt="QR de Ath Movil" />
                    </label>
                  </div>
                ) : null}
                {/*
                  <div>
                  <button className={this.state.validUpdate ? null : "ATHMovil_disabled"} disabled={this.state.validUpdate ? false : true} onClick={this.handleButton} id="ATHMovil_Checkout_Button"></button>                             
                 </div>*/}
              </Label>

              <Label>
                Software *
                <div>
                  <SelectSW onChange={this.handleChange} name="software">
                    {this.state.res.software.map((Software) => (
                      <option key={Software.id} value={Software.value}>
                        {Software.value}
                      </option>
                    ))}
                  </SelectSW>
                </div>
              </Label>


              {this.state.data.paymethod === "CREDIT-CARD" ? (
                <div id="form-group-credit-card">
                  <Label>
                    Nombre en la tarjeta *
                    <Input
                      onChange={this.handleChange}
                      name="nameoncard"
                      type="text"
                      placeholder="Nombre en la tarjeta"
                    />
                  </Label>
                  <Label>
                    Número Tarjeta Crédito/ Tipo de tarjeta *
                    <div className="DivForm">
                      <InputMask
                        className="inputCcNumber"
                        onChange={this.handleChange}
                        onBlur={this.handleChangeCardNumber}
                        name="creditcarnumber"
                        type="text"
                        placeholder="Número"
                        mask={this.state.mask}
                      />
                      <Selectll
                        onChange={this.handleChange}
                        name="cardtype"
                        value={this.state.data.cardtype}
                        disabled
                      >
                        {this.state.res.typeCard.map((s) => (
                          <option key={s.value} value={s.value}>
                            {s.value}
                          </option>
                        ))}
                      </Selectll>
                    </div>
                  </Label>
                  <Label>
                    Código de seguridad CVV2 *
                    <InputMask
                      className="inputMask"
                      onChange={this.handleChange}
                      name="securitycode"
                      type="text"
                      mask={this.state.maskCvv2}
                      placeholder="Código de seguridad"
                      value={this.state.data.securitycode}
                    />
                  </Label>
                  <Label>
                    Fecha de Expiración - Mes/Año *
                    <div>
                      <select
                        name="expDateM"
                        value={this.state.expMonth}
                        onChange={this.handleChange}
                        required
                      >
                        <option value="">
                          MM
                        </option>
                        {Array.from({ length: 12 }, (_, i) => (
                          <option key={i + 1} value={String(i + 1).padStart(2, "0")}>
                            {String(i + 1).padStart(2, "0")}
                          </option>
                        ))}
                      </select>
                      /
                      <select
                        name="expDateY"
                        value={this.state.expYear}
                        onChange={this.handleChange}
                        required
                      >
                        <option value="">
                          YYYY
                        </option>
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Label>
                </div>
              ) : null}
              {this.state.data.paymethod === "BANK-ACCOUNT" ? (
                <div id="form-group-debitcard">
                  <Label>
                    Nombre de cuenta *
                    <Input
                      onChange={this.handleChange}
                      name="accountNameBank"
                      type="text"
                      placeholder="Nombre de cuenta"
                    />
                  </Label>
                  <Label>
                    Número de cuenta *
                    <Input
                      className="inputMask"
                      onChange={this.handleChange}
                      name="accountNumberBank"
                      type="number"
                      max="999999999999999999999999999999"
                      placeholder="Número de cuenta"
                    />
                  </Label>
                  <Label>
                    Número de ruta *
                    <InputMask
                      className="inputMask"
                      onChange={this.handleChange}
                      name="routeNumberBank"
                      type="text"
                      placeholder="Número de ruta"
                      mask="999999999"
                    />
                  </Label>
                </div>
              ) : null}
              <Label>
                Revendedor *
                <div>
                  <SelectSW onChange={this.handleChange} name="idReseller" value={this.state.data.idReseller} disabled={this.state.linkReseller === true}>
                    {this.state.resellers.filter(reseller => reseller.status === true).map((reseller, index) => (
                      <option key={index} value={reseller.id}>{reseller.firstname + " " + reseller.lastname}</option>
                    ))
                    }
                    <option value={-1}>Ninguno</option>
                  </SelectSW>
                </div>
                <div style={{
                  display: "flex",
                  justifyContent: "center", 
                  alignItems: "center",         
                }}>
                  {this.state.linkReseller ?
                    <img alt="logo" style={{ padding:"2rem 1rem 0 1rem" }} width={250} src={this.state.imageReseller}></img> : null
                  }
                </div>
              </Label>
              
              <div className="row mt-3">
                <p className="billText"><strong>Anualidad + 1 terminal: $ {this.state.data.service}</strong></p>
                <p className="billText"><strong>Terminales adicionales: $ {this.state.additionalTerminal}
                </strong></p>
                <hr className="solid w-100"></hr>
                <p className="billText2"><strong>Total de pago: $ {this.state.totalAmount}</strong></p>

              </div>
              <ContainerTerms>
              <span className="terms-text">Al continuar con esta transacción, autorizas a Retail Manager PR LLC a procesar el cobro en nombre de <a href="#">IVUControlPR.com</a> por los servicios seleccionados. Este cargo será reflejado en tu método de pago bajo el nombre de <strong>Retail Manager PR LLC.</strong></span>
                <Label>
                  <input
                    type="checkbox"
                    name="terms"
                    onClick={this.handleChange}
                    defaultChecked={this.state.terms}
                  />
                  <Link target="_blank" to="/terms">
                    Acepto los términos y condiciones de uso
                  </Link>
                </Label>
              </ContainerTerms>

              {this.state.validForm ===
                false /*&& this.state.error != '') || (this.state.validUpdate == false && this.state.error != ''*/ ? (
                <MsjFail id="error">
                  <p className="error-message">
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <b>{this.state.error}</b>
                  </p>
                </MsjFail>
              ) : null}
              <ContainerButtonCA>
                {/*this.state.data.paymethod == "ATHMOVIL" ? null :*/}{" "}
                <Buttonn type="submit">Crear Cuenta</Buttonn>
              </ContainerButtonCA>
            </Formn>
          </div>
        </main >
        <footer>© 2021 IvuControlPR</footer>
      </div >
    );
  }
}
export default withRouter(Register);
