import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import React from "react";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import '../elements/UserDashboard.css'

/**
 * Componente que representa el modal para realizar la edición de un negocio
 */
class ResellerVerlastTransactions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditModal: false,
            validated: false,
            token: "",
            loading: false,
            lastTransactions: props.lastTransactions
        };
    }

    /**
     * Metodo utilizado para cargar información necesaria como ciudades, softwares y el token del usuario
     */
    async componentDidMount() {
    }

    /**
     * Metodo encargado de desplegar u ocultar el modal y cargar la información del negocio
     * @param {*} value Si es True entonces se despliega el modal, en caso contrario se oculta
     */
    verModal = (value) => {
        this.setState({
            showEditModal: value,
            validated: false
        })
    }

   
    /**
     * Metodo de renderizado
     * @returns html que contiene el botón que despliega el modal y el modal
     */
    render() {
        return (
            <React.Fragment>
                <Button className="btnEditar" onClick={() => this.verModal(true)}><strong>HISTORIAL</strong></Button>
                <Modal show={this.state.showEditModal} onHide={() => this.verModal(false)} dialogClassName="my-modal" >
                    {this.state.loading ?
                        <div className="spinner"></div>
                        :
                        <Form noValidate validated={this.state.validated} id="formEdit" onSubmit={this.handleSubmitEditModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Ultimas 10 transacciones</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <React.Fragment>
                                <div className="row">
                                  <Table responsive>
                                    <thead className="thead-dark">
                                        <tr>
                                          <th scope="col">ID del registro</th>
                                          <th scope="col">Fecha</th>
                                          <th scope="col">Hora</th>
                                          <th scope="col">Ciudad</th>
                                          <th scope="col">Número de comerciante</th>
                                          <th scope="col">IVU</th>
                                          <th scope="col">Impuesto municipal</th>
                                          <th scope="col">Código postal</th>
                                          <th scope="col">Nombre del vendedor</th>
                                          <th scope="col">Estado</th>
                                          <th scope="col">Creado el</th>
                                          <th scope="col">Impuesto estatal</th>
                                          <th scope="col">Nombre del comercio</th>
                                          <th scope="col">ID del comerciante</th>
                                          <th scope="col">Monto de venta</th>
                                          <th scope="col">Monto de la transacción</th>
                                          <th scope="col">Impuesto reducido</th>
                                          <th scope="col">Fecha de expiración</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.lastTransactions.map((transaction, index) => (
                                            <tr key={index}>
                                              <td>{transaction.recordId}</td>
                                              <td>{transaction.fecha}</td>
                                              <td>{transaction.hora}</td>
                                              <td>{transaction.merchantCity}</td>
                                              <td>{transaction.merchantNum}</td>
                                              <td>{transaction.ivu}</td>
                                              <td>{transaction.cityTaxAmount}</td>
                                              <td>{transaction.merchantZipCode}</td>
                                              <td>{transaction.vendorName}</td>
                                              <td>{transaction.merchantState}</td>
                                              <td>{transaction.createdOn}</td>
                                              <td>{transaction.stateTaxAmount}</td>
                                              <td>{transaction.merchantName}</td>
                                              <td>{transaction.merchantId}</td>
                                              <td>{transaction.salesAmount}</td>
                                              <td>{transaction.transactionAmount}</td>
                                              <td>{transaction.reducedTax}</td>
                                              <td>{transaction.expirationDate}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                  </Table>
                                </div>
                              </React.Fragment>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.verModal(false)}>
                                    Volver
                                </Button>
                            </Modal.Footer>
                        </Form>
                    }
                </Modal>
            </React.Fragment>
        )
    }

}

export default ResellerVerlastTransactions;