import React from "react";
import Navbar from "../componentes/Navbar";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import axios from "axios";
import Table from 'react-bootstrap/Table';
import '../elements/PayBill.css'
import ReactPaginate from 'react-paginate';
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import Logo from "../img/logo.png";
import PayBillConfirm from "./PayBillConfirm";
import PayBillMerchant from "./PayBillMerchant";

/**
 * Componente encargado de registrar el pago de un cliente y listar su historial de pagos
 * @param {object} props.match - Información de coincidencia de ruta proporcionada por withRouter.
 */
class PayBill extends React.Component {

    /**
    * Atributo de estado que contiene los atributos variables
    */
    state = {
        token: '',
        role: -1,
        searchText: "",
        currentPage: 0,
        perPage: 10, 
        pageCount: 0,
        currentPagePP: 0,
        perPagePP: 5, 
        pageCountPP: 0,
        client: {},
        payHistory: [],
        payMethod: "CREDIT-CARD",
        payDate: [''],
        totalAmount: 0,
        loading: false,
        loadingInit: false,
        services: [],
        service: {},
        searching: false,
        users: [],
        searchQuery: '',
        searchResults: [],
        showOptions: false,
        rejected: [],
        terminals: 0,
        referenceNumber: "",
        paymentMethods: [],
        additionalTerminal: 0,
        pendingPayments: []
    };

    /**
    * Referencia a el campo que contiene la información que ingresa el usuario de busqueda
    */
    searchRef = React.createRef()

    /**
     * Metodo encargado de obtener el token de usuario del localStorage y definirlo en el atributo de estado, obtener los servicios disponibles
     * para realizar el cobro respectivo y obtener los usuarios para realizar mostrar a la hora de realizar el filtro
     */
    async componentDidMount() {
        const token = localStorage.getItem('loggedIvuUser')
        const [header, payload, signature] = token.split('.');

        // Decodificar la carga útil (payload) que contiene la información del usuario
        const decodedPayload = JSON.parse(atob(payload));

        const role = decodedPayload['role']
        const { merchantNumber } = this.props.match.params;
        this.setState({ token: token, searching: false, role: role })
        if (merchantNumber !== undefined) {
            this.setState({
                searchText: merchantNumber
            }, () => {
                this.searchClient()
            })
        }
        const config2 = {
            method: 'get',
            url: global.URLBack + `/dashBoard/service`,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            withCredentials: true
        }

        await axios(config2)
            .then(res => {
                this.setState({
                    services: res.data
                })
                const config3 = {
                    method: 'get',
                    url: global.URLBack + `/dashBoard/superUser/paymentMethods`,
                }

                axios(config3).then(response2 => {
                    this.setState({ paymentMethods: response2.data })
                }).catch(error => {
                })
            }).catch(err => {
                localStorage.removeItem('loggedIvuUser')
                //localStorage.removeItem('role')
            })

        this.loadPendingPayments()

    }

    loadPendingPayments = async () => {
        try {
            const config = {
                method: 'get',
                url: global.URLBack + `/dashBoard/superUser/invoice/inProcess`,
                headers: { 'Authorization': 'Bearer ' + this.state.token },
                withCredentials: true
            };
    
            const res = await axios(config);
    
            if (res.data.length > 0) {
                const updatedEntities = await Promise.all(res.data.map(async entity => {
                    let configFilter = {
                        method: 'get',
                        url: `${global.URLBack}/dashBoard/superUser/usersFilter?filter=${entity.merchantId}`,
                        headers: { 'Authorization': 'Bearer ' + this.state.token },
                        withCredentials: true
                    };
    
                    try {
                        const res2 = await axios(configFilter);
                        const form = res2.data.forms[0];
    
                        if (form) {
                            entity.fullName = `${form.username} ${form.lastName} ${form.secondLastName}`;
                            entity.merchant_name = form.merchant_name;
                        }
                    } catch (error) {
                        console.error("Error al obtener datos:", error);
                    }
    
                    return entity;
                }));
    
                this.setState({
                    pendingPayments: updatedEntities,
                    pageCountPP: Math.ceil(updatedEntities.length / this.state.perPagePP) // 🔥 Calcula `pageCountPP`
                });
            }
        } catch (error) {
            console.error("Error en la carga de pagos:", error);
        }
    };

    /**
     * Metodo utilizado para obtener el valor de la busqueda que ingresó el usuario a partir de la referencia creada
     * @param {*} event 
     */
    handleSearchChange = (event) => {
        this.setState({ searchText: this.searchRef.current.value });
    }

    /**
     * Metodo utilizado para capturar los cambios realizados y asignarlos a la variable del state correspondiente
     * @param {*} event input que lanzó el evento y que contiene el nombre y valor a cambiar
     */
    handleChange = (event) => {
        this.setState({ [event.target.name]: [event.target.value] });
    }

    /**
     * Metodo utilizado para manejar la paginación de la tabla
     * @param {*} param0 Pagina seleccionada
     */
    handlePageChange = ({ selected }) => {
        this.setState({
            currentPage: selected,
            selectedElement: null, // Reinicia el elemento seleccionado al cambiar de página
        });
    };

    handlePageChangePP = ({ selected }) => {
        this.setState({
            currentPagePP: selected
        });
    };

    /**
    * Metodo utilizado para realizar la busqueda de la información del usuario y su historial de pagos
    */
    searchClient() {
        const config = {
            method: 'get',
            url: global.URLBack + `/dashBoard/superUser/invoice?filter=` + this.state.searchText,
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
            withCredentials: true
        }
        this.setState({ searching: true, loading: true })
        axios(config)
            .then(res => {

                this.setState({ loading: false })
                var pageC = Math.ceil(res.data.historialPagos.length / this.state.perPage);

                let rejected = []
                let terminalsCount = res.data.terminales.length
                res.data.historialPagos.forEach(element => {
                    if (element.rejected === 1 && element.paid === 0) {
                        rejected.push(element)
                    }
                });
                /*  if (res.data.historialPagos.length > 0 && res.data.historialPagos[0].rejected === 1) {
                     isRejected = true
                     terminalsCount = res.data.historialPagos[0].terminals
                 } */

                this.setState({
                    client: res.data,
                    pageCount: pageC,
                    payHistory: res.data.historialPagos,
                    rejected: rejected,
                    terminals: terminalsCount
                })

                /** Calcular el total a pagar y colocarlo en total amount */
                let payRejected = undefined
                if (rejected.length > 0) {
                    payRejected = rejected
                }
                this.setService(res.data.service, payRejected)


            }).catch(err => {
                this.setState({ client: [] })
                this.setState({ loading: false })
            })
    }

    /**
     * Metodo utilizado para asignar el valor del servicio y el monto total buscando el servicio que tiene asociado ese cliente en la lista de 
     * servicios que se obtienen del back y así asociar un valor
     * @param {*} serviceInitial nombre del servicio
     * @param {*} payRejected objeto del último pago en caso de ser rechazado. En caso de no haber sido rechazado este objeto es undefined
     */
    setService(serviceInitial, payRejected) {

        let total = 0
        if (payRejected !== undefined) {
            for (let i = 0; i < payRejected.length; i++) {
                total = total + payRejected[i].totalAmount + 25
            }
        }

        this.state.services.filter(service => service.serviceName === serviceInitial).map(service => {

            if (payRejected === undefined) {
                if (this.state.terminals === 0) {
                    total = (service.serviceValue)
                } else {
                    let additionalTerminals = 0
                    if (this.state.terminals >= 2 && this.state.terminals <= 5) {
                        additionalTerminals = this.state.client.terminals2to5
                    } else if (this.state.terminals >= 6 && this.state.terminals <= 9) {
                        additionalTerminals = this.state.client.terminals6to9
                    } else if (this.state.terminals >= 10) {
                        additionalTerminals = this.state.client.terminals10
                    }
                    this.setState({ additionalTerminal: additionalTerminals })
                    total = (service.serviceValue) + (additionalTerminals * (this.state.terminals - 1))
                }
            }

            this.setState({
                service: service
            })
        })
        this.setState({
            totalAmount: total
        })
    }

    /**
     * Metodo utilizado para listar posibles usuarios que coincidan con un criterio de busqueda ingresado por el usuario
     * para que posteriormente los seleccione y con ese usuario seleccionado se obtenga la información del cliente y su historial de pagos
     * @param {*} event Input que contiene el criterio de busqueda
     */
    handleInputChange = (event) => {
        const query = event.target.value;
        let showOptions = true
        var filteredUsers = []
        this.setState({ searchQuery: query });
        //TODO:AQUI SE COLOCA EL SERVICIO DEL BACKEND
        if (query.trim().length !== 0) {

            const config = {
                method: 'get',
                url: global.URLBack + `/dashBoard/superUser/usersFilter?filter=` + query,
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                },
                withCredentials: true
            }
            axios(config)
                .then(res => {
                    this.setState({ loading: false })
                    filteredUsers = res.data.forms
                    if (filteredUsers.length === 0 || query === "") {
                        showOptions = false
                    }
                    this.setState({ searchResults: filteredUsers, showOptions: showOptions });
                }).catch(err => {
                    this.setState({ loading: false })

                })


        } else {
            this.setState({ searchResults: [], showOptions: false });
        }

    };

    /**
     * Metodo utilizado para asignar cual fue el cliente seleccionado para obtener la información 
     * @param {*} option objeto del cliente seleccionado
     */
    handleSelectOption = async (option) => {
        await this.loadPendingPayments(); // Espera que termine antes de continuar
    
        this.setState(prevState => {
            const filteredPayments = prevState.pendingPayments.filter(p => p.merchantId == option.merchantID);
            const newPageCount = Math.ceil(filteredPayments.length / prevState.perPagePP); // Recalcula las páginas
    
            return {
                pendingPayments: filteredPayments,
                pageCountPP: newPageCount, // Actualiza el total de páginas
                currentPagePP: 0, // Reinicia la página actual para evitar estar en una página inválida
                showOptions: false,
                searchText: option.merchantID
            };
        }, () => {
            this.searchClient();
        });
    };
    
    /**
     * Metodo utilizado para registrar el pago
     */
    saveBill = () => {

        let date = this.state.payDate[0].split("T")[0]
        let time = this.state.payDate[0].split("T")[1] + ":00"

        let payM = ""
        if (typeof (this.state.payMethod) === "string") {
            payM = this.state.payMethod
        } else {
            payM = this.state.payMethod[0]
        }

        let rejected = this.state.rejected.map(invoice => ({
            "invoiceNumber": invoice.invoiceNumber,
            "totalAmount": invoice.totalAmount
        }));
        let reference = this.state.referenceNumber[0] === undefined ? "" : this.state.referenceNumber[0]
        if (rejected.length > 0) {
            rejected.map(rejectedBill => {
                reference = reference + " " + "FACTURA " + rejectedBill.invoiceNumber + " $" + rejectedBill.totalAmount + " + " + "PENALIDAD $25"
            })
        }
        let bill =
        {
            date: date,
            totalAmount: this.state.totalAmount,
            paymentMethod: payM,
            time: time,
            merchantId: this.state.client.merchantID,
            terminals: this.state.terminals,
            idUser: this.state.client.idUser,
            referenceNumber: reference,
            rejectedPayments: rejected
        }
        const config = {
            method: 'post',
            url: global.URLBack + `/dashBoard/superUser/invoice`,
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
            data: bill,
            withCredentials: true
        }
        axios(config)
            .then(res => {
                if (res.status === 201) {
                    this.searchClient()
                    /*  const { client } = this.state;
                     const nuevoHistorial = [res.data, ...client.historialPagos];
                     
                     let  nuevoHistorialActualizado=[]
                     if(rejected.length>0){
                         rejected.map(rejectedInvoice=>{
                             nuevoHistorialActualizado = nuevoHistorial.map(invoice => {
                                 if (invoice.invoiceNumber === rejectedInvoice.invoiceNumber) {
                                   // Modificar la propiedad "paid" del objeto encontrado
                                   return { ...invoice, paid: 1 };
                                 }
                                 return invoice;
                               });
     
                         })
                         
                     }else{
                         nuevoHistorialActualizado = nuevoHistorial
                     }    
                     
                     let rejectedV = []
                         nuevoHistorialActualizado.forEach(element => {
                             if (element.rejected === 1 && element.paid === 0) {
                                 rejectedV.push(element)
                             }
                         });
                     
                     this.setState({
                         client: {
                             ...client,
                             historialPagos: nuevoHistorialActualizado
                         },
                         payHistory: nuevoHistorialActualizado,
                         rejected: rejectedV
                     }, () => {
                         var pageC = Math.ceil(this.state.client.historialPagos.length / this.state.perPage);
                         this.setState({
                             pageCount: pageC,
                         })
                         Swal.fire({
                             title: "Factura guardada correctamente",
                             icon: 'success'
                         })
                         this.setState({
                             payDate: ['']
                         })
                     }); */
                }


            }).catch(err => {
                Swal.fire({
                    title: "Ocurrio un error al guardar la factura",
                    text: "Intentelo más tarde",
                    icon: 'error'
                })
            })
    }

    /**
     * Metodo utilizado para imprimir una factura
     * @param {*} payDetails Información de la factura
     */
    handlePrint = (payDetails) => {
        const style = `
                
        /* Estilos para ocultar en la vista normal */
        .printable-content .print-only {
          display: none;
        }
        
        /* Estilos específicos para la vista de impresión */
        @media print {
          .printable-content .print-only {
            display: block;
          }
        
          .invoice-container {
            font-family: Arial, sans-serif;
            width: 80%;
            margin: 0 auto;
            padding: 20px;
            background-color: #ffffff;
            border: 1px solid #d3d3d3;
            border-radius: 10px;
          }
        
          .invoice-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 1px solid #d3d3d3;
            margin-bottom: 10px;
          }
        
          .invoice-date {
            text-align: right;
          }
        
          .invoice-details {
            margin-top: 20px;
          }
        
          .invoice-total {
            margin-top: 40px;
            text-align: center;
            padding-top: 20px;
            border-top: 1px solid #d3d3d3;
          }
        
          .customer-info,
          .address-info,
          .service-info,
          .software-info,
          .payment-info {
            padding: 10px;
            background-color: #f2f2f2;
            border-radius: 5px;
          }
        
          h1,
          h2,
          h3 {
            color: #007bff;
          }
        
          p {
            margin: 8px 0;
            font-size: larger;
          }
        
          body {
            font-family: Arial, sans-serif;
            font-size: 14px;
          }
        }
        
        

      `;

        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);

        const content = `
          <html>
            <head>
              <style>${style}</style>
            </head>
            <body>
              ${this.getPrintableContent(payDetails)}
            </body>
          </html>
        `;

        const doc = iframe.contentWindow.document;
        doc.open();
        doc.write(content);
        doc.close();

        // Iniciar la impresión
        iframe.contentWindow.print();
    };

    /**
     * Metodo utilizado para retornar el html que se muestra al imprimir una factura
     * @param {*} payDetails 
     * @returns 
     */
    getPrintableContent = (payDetails) => {
        const terminalInfo = this.state.client.terminales.map((terminales, index) => (
            `<div style="display: flex;
            justify-content: space-between;
            margin-bottom: 10px;">
                <p><strong>Terminal:</strong> ${terminales.terminalNumber}</p>
                <p style="margin-right:40%"><strong>Software Seleccionado:</strong> ${terminales.software}</p>
            </div>
            `
        ))
        const formattedTerminalInfo = terminalInfo.join("");
        return `
        <div class="printable-content">
        <!-- Contenido que deseas imprimir -->
        <div className="print-only">
          <div className="invoice-container" style=" font-family: Arial, sans-serif;
          ">
            <div className="invoice-header" style=" display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 1px solid #d3d3d3;
            margin-bottom: 10px;">
              <img src="${Logo}" alt="Logo" width="200px" height="60px" />
              <div className="invoice-date" style=" text-align: right;">
                <p style="font-size: large;"><strong>Fecha:</strong> ${payDetails.date}</p>
                <p style="font-size: large;"><strong>Hora:</strong> ${payDetails.time}</p>
              </div>
            </div>
           
            <div className="customer-info" style=" 
            padding-left: 10px;
            background-color: #f2f2f2;
            border-radius: 5px;">
            ${payDetails.rejected === 1 ?
                `<h2 style="color:red"><strong>PAGO RECHAZADO</strong></h2> ` : ` `
            }
              <h3>Información del Cliente</h3>
              <p><strong>Nombre:</strong>${this.state.client.username}</p>
              <p><strong>Nombre de Negocio:</strong> ${this.state.client.merchantName}</p>
              <p><strong>Número Mercantil:</strong> ${this.state.client.merchantNumber} </p>
              <p><strong>Email:</strong> ${this.state.client.email}</p>
              <p><strong>Telefono:</strong> ${this.state.client.phone}</p>
            </div>
            <div className="address-info"  style="  
            padding-left: 10px;
            background-color: #f2f2f2;
            border-radius: 5px;">
              <h3>Dirección</h3>
              <p><strong>Dirección :</strong> ${this.state.client.address}</p>
              <p><strong>Ciudad/Estado/Código Zip:</strong>${this.state.client.town_name}/${this.state.client.state}/${this.state.client.zipcode} </p>
            </div>
            <div className="service-info"  style="  
            padding-left: 10px;
            background-color: #f2f2f2;
            border-radius: 5px;">
              <h3>Servicio</h3>
              <p><strong>Servicio Seleccionado:</strong> ${this.state.client.service}</p>
              <p><strong>Terminales Fiscales:</strong> ${payDetails.terminals}</p>
            </div>
            <div className="software-info"  style="  
            padding-left: 10px;
            background-color: #f2f2f2;
            border-radius: 5px;">
              <h3>Software</h3>
            
             ${formattedTerminalInfo}
              <p><strong>Fecha de Expiración:</strong> ${this.state.client.expirationDate}</p>
            </div>
            <div className="payment-info"  style="  
            padding-left: 10px;
            padding-bottom: 10px;
            background-color: #f2f2f2;
            border-radius: 5px;">
              <h3>Información de Pago</h3>
              <p><strong>Método de Pago:</strong> ${payDetails.paymentMethod}</p>  
              ${payDetails.paymentMethod === "CREDIT-CARD" ?
                `<p><strong>Número de referencia:</strong> ${payDetails.referenceNumber}</p>` : ` `
            }                 
            </div>
            <div className="invoice-total" style=" 
            text-align: center;
            padding-top: 20px;
            border-top: 1px solid #d3d3d3;">
              <h2><strong>Total Pagado</strong></h2>
              ${payDetails.rejected === 1 ?
                `<h2 style="color:black"><del><strong>$ ${payDetails.totalAmount}</strong></del></h2>` :
                `<h2 style="color:black"><strong>$ ${payDetails.totalAmount}</strong></h2>`
            }
            </div>
          </div>
        </div>
      </div>
      
        `;
    };

    /**
     * Metodo utilizado para marcar un pago como rechazado
     * @param {*} payment objeto que contiene la información del pago
     * @param {*} index indice en el cual se encuentra ese pago en el historial de pagos
     */
    rejectPayment = (payment, index) => {
        if (payment.rejected === 1) {
            Swal.fire({
                title: "La factura # " + payment.invoiceNumber + " ya fue notificada como rechazada",
                icon: "info"
            })
        } else {
            Swal.fire({
                title: "¿Está seguro de que desea rechazar el pago?",
                icon: "warning",
                showCancelButton: true,
                showConfirmButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonText: "Rechazar Pago",
                reverseButtons: true
            }).then((response) => {
                if (response.isConfirmed) {
                    this.setState({ loading: true })
                    const config = {
                        method: 'put',
                        url: global.URLBack + `/dashBoard/superUser/invoice/rejected/` + payment.invoiceNumber,
                        headers: {
                            'Authorization': 'Bearer ' + this.state.token
                        },
                        withCredentials: true
                    }

                    axios(config).then(res => {
                        this.setState({ loading: false })
                        if (res.data.rta) {
                            Swal.fire({
                                title: "El pago registrado en la factura # " + payment.invoiceNumber + " se ha notificado y actualizado correctamente",
                                icon: "success"
                            })
                            const historialPagos = [...this.state.payHistory];
                            const { client } = this.state;
                            historialPagos[index].rejected = 1;
                            historialPagos[index].referenceNumber = historialPagos[index].referenceNumber + " (PAGO RECHAZADO)";
                            let rejected = this.state.rejected
                            rejected.push(historialPagos[index])
                            let total = this.state.totalAmount
                            total = total + historialPagos[index].totalAmount + 25

                            this.setState({
                                client: {
                                    ...client,
                                    historialPagos: historialPagos
                                },
                                payHistory: historialPagos,
                                rejected: rejected,
                                totalAmount: total
                            });

                        } else {
                            if (res.status === 200) {
                                Swal.fire({
                                    title: "La factura # " + payment.invoiceNumber + " ya fue notificada como rechazada",
                                    icon: "info"
                                })
                            } else if (res.status === 404) {
                                Swal.fire({
                                    title: "Ha ocurrido un error",
                                    text: "La factura no fue encontrada",
                                    icon: "error"
                                })
                            } else {
                                Swal.fire({
                                    title: "Ha ocurrido un error",
                                    text: "Por favor intentelo más tarde",
                                    icon: "error"
                                })
                            }

                        }
                    }).catch((error) => {
                        this.setState({ loading: false })
                        Swal.fire({
                            title: "Ha ocurrido un error",
                            text: "Por favor intentelo más tarde",
                            icon: "error"
                        })
                    })
                }
            })
        }

    }

    /**
     * Metodo utilizado para verificar si la fecha actual es menor o igual que una fecha limite
     * @param {*} fecha fecha limite
     * @returns True si cumple la condición, False en caso contrario
     */
    isFechaValida = (fecha) => {
        const fechaActual = new Date();
        const fechaLimite = new Date(fecha);
        fechaLimite.setDate(fechaLimite.getDate() + 30);
        const formatDate = date => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        let limit = formatDate(fechaLimite)
        let actual = formatDate(fechaActual)
        return actual <= limit;
    };

    /**
     * Renderiza la barra de navegación en función del estado actual.
     *
     * @return El componente Navbar renderizado.
     */
    renderNavbar() {
        // Puedes acceder al estado actualizado aquí y pasar el valor a Navbar
        const { role } = this.state;
        if (Number(role) !== -1) {
            return <Navbar SupUser={role} />;
        }

    }


    handlePaymentConfirmed = async (pay) => {
        //await this.loadPendingPayments();
        await this.searchClient()
        this.setState(prevState => ({
            pendingPayments: prevState.pendingPayments.filter(p => p.invoiceNumber !== pay.invoiceNumber)
        }));
    };

    updateInfo = (value) => {

        if (value) {
            window.location.reload()
        }
    }

    /**
     * Metodo de renderizado
     * @returns Html que contiene el campo de busqueda, la información del usuario, el formulario de registro de pago y su historial de pagos
     */
    render() {
        const { currentPage, perPage, payHistory, searchQuery, searchResults, 
            showOptions, role, pendingPayments, currentPagePP, perPagePP, pageCountPP } = this.state;
        const offset = currentPage * perPage;
        var pageC = this.state.pageCount
        var currentPageData = []
        if (payHistory.length > 0) {
            currentPageData = payHistory.slice(offset, offset + perPage);
        } else {
            currentPageData = payHistory
        }

        const offsetPP = currentPagePP * perPagePP;
        const currentPagePPData = pendingPayments.slice(offsetPP, offsetPP + perPagePP);

        return (
            <React.Fragment>
                {this.renderNavbar()}
                <div className='mainn'>
                    <div className="sonn1" style={{ height: "50px" }}>
                        {Number(role) !== 3 ?
                            <p className='titulo'><strong>Entrar pago de factura</strong></p> :
                            <p className='titulo'><strong>Historial pago de factura</strong></p>

                        }
                    </div>

                    <div className="sonn">
                        {this.state.loadingInit ?
                            <div className="spinner"></div>
                            :
                            <React.Fragment>
                                <Alert variant="info" className="infoAlert">
                                    Recuerde que puede realizar la busqueda ingresando el nombre del cliente, nombre del negocio, el ID del merchant o el número del merchant.
                                </Alert>
                                <div className="row">
                                    <div className="col-1">
                                        <h4 className="mt-3" >
                                            Nombre:
                                        </h4>
                                    </div>
                                    <div className="col-10">

                                        <Form.Group className="mt-3" >
                                            <Form.Control
                                                type="text"
                                                value={searchQuery}
                                                onChange={this.handleInputChange}
                                                placeholder="Escribe y selecciona una opción..."
                                            />
                                            {showOptions && searchResults.length > 0 && (
                                                <ul className="autocomplete-options">
                                                    {searchResults.map((result, index) => (
                                                        <li
                                                            key={index}
                                                            onClick={() => this.handleSelectOption(result)}
                                                            className="autocomplete-option"
                                                        >
                                                            <div><strong>
                                                                {result.merchantID}
                                                            </strong>
                                                            </div>
                                                            <div><strong>Nombre: </strong>
                                                                {result.username + " " + result.lastName + " " + result.secondLastName}
                                                            </div>
                                                            <div><strong>Negocio: </strong>
                                                                {result.merchant_name}
                                                            </div>

                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </Form.Group>
                                        {/* <Form>
                                    <Form.Group className="mb-3" id="ControlInput1">
                                        <Form.Label
                                            className="mb-3"
                                        >    </Form.Label>
                                        <Form.Control type="text" placeholder="Ingrese su busqueda" ref={this.searchRef} value={this.state.searchText} onChange={this.handleSearchChange} />
                                    </Form.Group>
                                </Form> */}
                                    </div>
                                    {/* <div className="col">
                                <Button className="btnSearch" variant="primary" onClick={this.searchClient.bind(this)} disabled={this.state.searchText.trim().length == 0}>Buscar</Button>
                            </div> */}
                                </div>
                            </React.Fragment>
                        }
                        {this.state.loading ?
                            <div className="spinner"></div>
                            :
                            <React.Fragment>
                                {(Object.keys(this.state.client).length > 1 && this.state.searching === true) ?
                                    <div className="row">
                                        <div className="col">
                                            {this.state.client.username !== undefined ?
                                                <h5>Nombre: {this.state.client.username}</h5>
                                                : <h5>Nombre:</h5>
                                            }
                                            <h5>Negocio: {this.state.client.merchantName}</h5>
                                            <h5>Dirección: {this.state.client.address}</h5>
                                        </div>
                                        <div className="col">
                                            <h5>Telefono:  {this.state.client.phone}</h5>
                                            <h5>Merchant ID:  {this.state.client.merchantID}</h5>
                                            <h5>Merchant #:  {this.state.client.merchantNumber}</h5>
                                        </div>
                                        <div className="col">
                                            <h5>Ultima transacción:  {this.state.client.lastTransaction}</h5>
                                            <h5>Fecha Expiración:  {this.state.client.expirationDate}</h5>
                                        </div>
                                        <div className="col">
                                            <h5>Estado:  {this.state.client.status}</h5>

                                            <PayBillMerchant key={this.state.client.merchantID} merchant={this.state.client.merchantID} bussinessname={this.state.client.merchantName} buttonName={0} updateDates={this.updateInfo}></PayBillMerchant>

                                        </div>
                                    </div> : null
                                }
                                {Object.keys(this.state.client).length <= 1 && this.state.searching === true ?
                                    <Alert variant="warning" className="infoAlert">
                                        Su criterio de busqueda no coincidió con ningun registro
                                    </Alert> : null
                                }
                            </React.Fragment>
                        }

                    </div>

                    <div className="sonn">
                        { pendingPayments.length > 0 ?
                            <React.Fragment>
                                <p className='titulo'>Pagos pendientes</p>
                                
                                <Table responsive className="custom-table" >
                                    <thead className="tr1">
                                        <tr >
                                            <th scope="col">Nombre</th>
                                            <th scope="col">Negocio</th>
                                            <th scope="col">Merchant ID</th>
                                            <th scope="col"># Factura</th>
                                            <th scope="col">Fecha</th>
                                            <th scope="col">Hora</th>
                                            <th scope="col">Metodo de pago</th>
                                            <th scope="col">Terminales</th>
                                            <th scope="col">Descripcion de pago</th>
                                            <th scope="col">Total</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPagePPData.map((pay, index) => (
                                            <tr key={index}>
                                                <td>{pay.fullName}</td>
                                                <td>{pay.merchant_name}</td>
                                                <td>{pay.merchantId}</td>
                                                <td>{pay.invoiceNumber}</td>
                                                <td>{pay.date}</td>
                                                <td>{pay.time}</td>
                                                <td>{pay.paymentMethod}</td>
                                                <td>{pay.terminals}</td>
                                                <td>{pay.paymentDescription}</td>
                                                <td>$ {pay.totalAmount}</td>
                                                <td>
                                                    <PayBillConfirm pay={pay} updateList={this.handlePaymentConfirmed} token={this.state.token}></PayBillConfirm>                                                    
                                                </td>
    
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
    
                                <ReactPaginate
                                    pageCount={pageCountPP}
                                    onPageChange={this.handlePageChangePP}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    forcePage={this.state.currentPagePP} // Establece la página actual activa en el componente de paginación
                                />
    
    
                            </React.Fragment>
                            :
                            <Alert variant="secondary" className="infoAlert">
                                No hay pagos pendientes por confirmar.
                            </Alert>
                        }
                       
                    </div>



                    {(Object.keys(this.state.client).length > 1 && this.state.searching === true) ?
                        <React.Fragment>
                            {Number(role) !== 3 ?
                                < div className="sonn1" style={{ height: "50px" }}>
                                    <p className='titulo'>Entrar pago</p>
                                </div> : null
                            }

                            <div className="sonn">
                                {
                                /*
                                Number(role) !== 3 ?
                                    <div className="row mt-3">
                                        <div className="col-3">
                                            <h5>Metodo de pago: </h5>
                                            <Form.Select aria-label="Default select example" required name="payMethod" value={this.state.payMethod} onChange={this.handleChange}>
                                                {this.state.paymentMethods.map((method, index) => (
                                                    <option key={index} value={method.code}>{method.name}</option>
                                                ))
                                                }
                                                <option value="CASH">Efectivo</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid" >
                                                Por favor seleccione un metodo de pago
                                            </Form.Control.Feedback>
                                            {this.state.rejected.length <= 0 ?
                                                <div className="row mt-3">
                                                    <p className="billText"><strong>Anualidad + 1 terminal: $ {this.state.service.serviceValue}</strong></p>
                                                    <p className="billText"><strong>Terminales adicionales: $ {this.state.terminals > 0 ?
                                                        this.state.additionalTerminal * (this.state.terminals - 1) :
                                                        this.state.additionalTerminal * (this.state.terminals)}</strong></p>
                                                    <hr className="solid w-100"></hr>
                                                    <p className="billText2"><strong>Total de pago: $ {this.state.totalAmount}</strong></p>

                                                </div>
                                                :
                                                <React.Fragment>
                                                    {this.state.rejected.map((rej, index) => (
                                                        <div className="row mt-3" key={index}>
                                                            <p className="billText"><strong>Valor Factura Anterior # {rej.invoiceNumber}: $ {rej.totalAmount}</strong></p>
                                                            <p className="billText2"><strong>Pago Rechazado: $ 25</strong></p>
                                                            <hr className="solid w-100"></hr>
                                                            <p className="billText2"><strong>Total de pago: $ {rej.totalAmount + 25}</strong></p>

                                                        </div>
                                                    ))}
                                                    {this.state.rejected.length > 1 ?
                                                        <React.Fragment>
                                                            <hr className="solid w-100"></hr>
                                                            <p className="billText2"><strong>Total completo a pagar: $ {this.state.totalAmount}</strong></p>
                                                        </React.Fragment> : null
                                                    }

                                                </React.Fragment>


                                            }

                                        </div>
                                        <div className="col-3 " style={{ marginLeft: "3%" }}>
                                            <h5>Fecha de pago: </h5>
                                            <input type="datetime-local" id="start" name="payDate" className="w-100" required
                                                value={this.state.payDate} onChange={this.handleChange}
                                            />
                                            <br></br>
                                            <h5 className="mt-3">Referencia: </h5>
                                            <input type="text" id="referenceNumber" name="referenceNumber" className="w-100"
                                                value={this.state.referenceNumber} onChange={this.handleChange}
                                            />
                                            {' '}<Button variant="primary mt-3 w-50" disabled={this.state.payDate[0] === ''} onClick={this.saveBill}>Guardar</Button>

                                        </div>
                                        <div className="col-5 " style={{ paddingLeft: "7%" }}>
                                            <Table striped responsive className="table_edit ">
                                                <thead>
                                                    <tr>
                                                        <th>Terminales</th>
                                                        <th >Software</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.client.terminales.map((terminals, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{terminals.terminalNumber}</td>
                                                                <td>{terminals.software}</td>
                                                            </tr>
                                                        )
                                                    })

                                                    }
                                                </tbody>
                                            </Table>

                                        </div>
                                    </div> : null*/
                                }
                                {this.state.payHistory.length > 0 ?
                                    <React.Fragment>
                                        <h4 className="mt-3">HISTORIAL DE PAGOS</h4>
                                        <Table responsive className="custom-table" >
                                            <thead className="tr1">
                                                <tr >

                                                    <th scope="col"># Factura</th>
                                                    <th scope="col">Fecha</th>
                                                    <th scope="col">Hora</th>
                                                    <th scope="col">Metodo de pago</th>
                                                    <th scope="col">Terminales</th>
                                                    <th scope="col">Total</th>
                                                    <th scope="col"># de Referencia</th>
                                                    <th scope="col"></th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentPageData.map((pay, index) => (
                                                    <tr key={index}>
                                                        <td>{pay.invoiceNumber}</td>
                                                        <td>{pay.date}</td>
                                                        <td>{pay.time}</td>
                                                        <td>{pay.paymentMethod}</td>
                                                        <td>{pay.terminals}</td>
                                                        <td>$ {pay.totalAmount}</td>
                                                        <td>{pay.referenceNumber}</td>
                                                        <td>
                                                            <Button variant="outline-primary" onClick={() => this.handlePrint(pay)}>
                                                                Imprimir
                                                            </Button>
                                                        </td>
                                                        {pay.paymentMethod === "BANK-ACCOUNT" && this.isFechaValida(pay.date) ?
                                                            <td>
                                                                <Button variant="outline-danger" onClick={() => this.rejectPayment(pay, index)}>
                                                                    Rechazado
                                                                </Button>
                                                            </td> : <td> </td>
                                                        }

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>

                                        <ReactPaginate
                                            pageCount={pageC}
                                            onPageChange={this.handlePageChange}
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            forcePage={this.state.currentPage} // Establece la página actual activa en el componente de paginación
                                        />


                                    </React.Fragment> :
                                    <Alert variant="secondary" className="infoAlert">
                                        Aun no cuenta con un historial de pagos
                                    </Alert>
                                }
                            </div>
                        </React.Fragment> : null
                    }
                </div>
            </React.Fragment >
        )
    }
}

export default withRouter(PayBill)