import React from "react";
import axios from "axios";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ReactPaginate from 'react-paginate';
import Navbar from "../componentes/Navbar";
import '../elements/MonthActivity.css';
import Logo from "../img/logo.png";

import Alert from 'react-bootstrap/Alert';

/**
 * Componente utilizado para  enseñar un reporte de pagos de todos los clientes en un rango de fechas
 */
class PayReport extends React.Component {

    state = {
        token: '',
        role: -1,
        currentPage: 0,
        perPage: 10, // Número de elementos por página
        pageCount: 0,
        payHistory: [],
        loading: false,
        startDate: "",
        endDate: "",
        showRange: false,
        showStartDate: "",
        showEndDate: "",
        onlyDate: false

    };

    /**
    * Metodo encargado de obtener el token de usuario del localStorage y definirlo en el atributo de estado
    */
    componentDidMount() {
        const token = localStorage.getItem('loggedIvuUser')
        const [header, payload, signature] = token.split('.');

        // Decodificar la carga útil (payload) que contiene la información del usuario
        const decodedPayload = JSON.parse(atob(payload));

        const role = decodedPayload['role']
        this.setState({ token: token, role: role }, () => {
            this.currentMonthTransactions()
        })

    }

    /**
   * Metodo utilizado para capturar los cambios realizados y asignarlos a la variable del state correspondiente
   * @param {*} event input que lanzó el evento y que contiene el nombre y valor a cambiar
   */
    handleChange = (event) => {

        this.setState({ [event.target.name]: event.target.value }, () => {
            if (!this.state.showRange) {
                this.setState({ onlyDate: true })
                this.dateTransactions()
            } else {
                this.setState({ onlyDate: false })
            }

        });
    }

    /**
    * Metodo utilizado para manejar la paginación de la tabla
    * @param {*} param0 Página seleccionada
    */
    handlePageChange = ({ selected }) => {
        this.setState({
            currentPage: selected,
            selectedElement: null, // Reinicia el elemento seleccionado al cambiar de página
        });
    };

    /**
     * Despliega el rango de fechas
     */
    showDates = () => {
        this.setState(
            {
                showRange: true
            }
        )
    }

    /**
     * Obtiene las transacciones del mes actual
     */
    currentMonthTransactions = () => {
        const currentDate = new Date();
        const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

        const formatDate = date => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        let startD = formatDate(firstDay)
        let endD = formatDate(lastDay)

        this.setState({
            showStartDate: startD,
            showEndDate: endD,
            startDate: "",
            endDate: ""
        })

        const config = {
            method: 'get',
            url: global.URLBack + `/dashBoard/superUser/invoice/report?dateFrom=` + startD + '&dateTo=' + endD,
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
            withCredentials: true
        }
        this.setState({ loading: true })
        axios(config)
            .then(res => {
                this.setState({ loading: false })
                if (res !== undefined) {
                    var pageC = Math.ceil(res.data.length / this.state.perPage);
                    this.setState({
                        pageCount: pageC,
                        payHistory: res.data
                    })
                }
            }).catch(err => {
                this.setState({
                    pageCount: 0,
                    payHistory: []
                })
                this.setState({ loading: false })
            })
    }

    /**
     * Obtiene las transacciones del mes anterior
     */
    lastMonthTransactions = () => {
        const currentDate = new Date();
        const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
        const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

        const formatDate = date => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        let startD = formatDate(firstDay)
        let endD = formatDate(lastDay)

        this.setState({
            showStartDate: startD,
            showEndDate: endD,
            startDate: "",
            endDate: ""
        })

        const config = {
            method: 'get',
            url: global.URLBack + `/dashBoard/superUser/invoice/report?dateFrom=` + startD + '&dateTo=' + endD,
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
            withCredentials: true
        }
        this.setState({ loading: true })
        axios(config)
            .then(res => {
                this.setState({ loading: false })
                if (res !== undefined) {
                    var pageC = Math.ceil(res.data.length / this.state.perPage);
                    this.setState({
                        pageCount: pageC,
                        payHistory: res.data
                    })
                }
            }).catch(err => {
                this.setState({
                    pageCount: 0,
                    payHistory: []
                })
                this.setState({ loading: false })
            })
    }

    /**
     * Obtiene las transacciones entre una fecha de inicio y fin
     */
    dateTransactions = () => {
        let endD = this.state.endDate
        if (!this.state.showRange) {
            endD = this.state.startDate
        }
        const config = {
            method: 'get',
            url: global.URLBack + `/dashBoard/superUser/invoice/report?dateFrom=` + this.state.startDate + '&dateTo=' + endD,
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
            withCredentials: true
        }
        this.setState({ loading: true })
        axios(config)
            .then(res => {
                this.setState({ loading: false })
                if (res !== undefined) {
                    var pageC = Math.ceil(res.data.length / this.state.perPage);
                    this.setState({
                        pageCount: pageC,
                        payHistory: res.data
                    })
                }
            }).catch(err => {
                this.setState({
                    pageCount: 0,
                    payHistory: []
                })
                this.setState({ loading: false })
            })
    }

    /**
     * Metodo utilizado para imprimir el reporte
     */
    handlePrint = () => {

        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);

        const content = `
          <html>
            <head>
            </head>
            <body>
              ${this.getPrintableContent()}
            </body>
          </html>
        `;

        const doc = iframe.contentWindow.document;
        doc.open();
        doc.write(content);
        doc.close();

        // Iniciar la impresión
        iframe.contentWindow.print();
    };

    /**
        * Metodo utilizado para retornar el html que se muestra al imprimir un reporte
        * @returns html que se va a imprimir
        */
    getPrintableContent = () => {
        let fecha = ""
        if (this.state.showRange && this.state.startDate.length !== 0 && this.state.endDate.length !== 0) {
            fecha = this.state.startDate + " - " + this.state.endDate
        } else {
            if (this.state.onlyDate) {
                fecha = this.state.startDate
            } else {
                fecha = this.state.showStartDate + " - " + this.state.showEndDate
            }

        }
        const { payHistory } = this.state
        const payList = payHistory.map((pay, index) => (
            `
            <tr key=${index}>
            <td>${pay.username}</td>
            <td>${pay.merchantName}</td>
            <td>${pay.merchantID}</td>
            <td>${pay.invoiceNumber}</td>
            <td>${pay.date}</td>
            <td>${pay.time}</td>
            <td>${pay.paymentMethod}</td>
            <td>${pay.terminals}</td>
            <td>${pay.totalAmount}</td>
            <td>${pay.referenceNumber}</td>
         </tr>
        `
        ))
        const formattedTransactions = payList.join("");
        return `
   
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Reporte de Transacciones</title>
      <style>
      body {
        font-family: Arial, sans-serif;
        background-color: #f5f5f5;
        margin: 0;
        padding: 0;
      }
  
      .report-container {
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        padding: 20px;
        margin: 20px auto;
      }
  
      .report-header {
        text-align: center;
        font-size: 24px;
        margin-bottom: 20px;
        color: #3498db;
      }
  
      .transaction-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ccc;
        padding: 10px 0;
      }
  
      .transaction-label {
        color: #3498db;
        width: 150px;
      }
  
      .transaction-data {
        flex: 1;
      }
      .transaction-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
      }
  
      .transaction-table th,
      .transaction-table td {
        border: 1px solid #ccc;
        padding: 10px;
        text-align: left;
      }
  
      .transaction-label {
        color: black;
        font-weight: bold;
      }
      </style>
    </head>
    <body>
    <div class="printable-content">
    <!-- Contenido que deseas imprimir -->
    <div className="print-only">
    <img src="${Logo}" alt="Logo" width="200px" height="60px" />
      <div class="report-container">   
      

        <h1 class="report-header">Reporte de Pagos</h1>
        <div class="transaction-row">
        <span class="transaction-label" style="width:50%">Fecha: ${fecha}</span>
        <span class="transaction-data"></span>
        </div>
        <table class="transaction-table">
        <tr>
          <th class="transaction-label">Nombre</th>
          <th class="transaction-label">Negocio</th>
          <th class="transaction-label">Merchant ID</th>
          <th class="transaction-label"># de Factura</th>
          <th class="transaction-label">Fecha</th>
          <th class="transaction-label">Hora</th>
          <th class="transaction-label">Metodo de pago</th>
          <th class="transaction-label">Terminales</th>
          <th class="transaction-label">Total	</th>
          <th class="transaction-label"># de referencia</th>
        </tr>
       ${formattedTransactions}
      </table>
    
      </div>
      
    </div>
    </div>
    </body>
    </html>
    
  
    `;
    };

    /**
     * Renderiza el componente de la barra de navegación basado en el estado actual del rol.
     *
     * @return  El componente Navbar con la propiedad SupUser establecida con el valor del estado actual del rol, o null si el rol es -1.
     */
    renderNavbar() {
        // Puedes acceder al estado actualizado aquí y pasar el valor a Navbar
        const { role } = this.state;
        if (Number(role) !== -1) {
            return <Navbar SupUser={role} />;
        }

    }


    /**
     * Metodo de renderizado
     * @returns html que contiene la tabla de transacciones junto con los elementos de busqueda
     */
    render() {

        const { currentPage, perPage, payHistory } = this.state;
        const offset = currentPage * perPage;
        var pageC = this.state.pageCount
        var currentPageData = []
        if (payHistory.length > 0) {
            currentPageData = payHistory.slice(offset, offset + perPage);
        } else {
            currentPageData = payHistory
        }
        return (

            <React.Fragment>
                {this.renderNavbar()}
                <div className='mainn'>
                    <div className="sonn1" style={{ height: "50px" }}>
                        <p className='titulo'><strong>Reporte de pagos</strong></p>
                    </div>
                    <div className="sonn">
                        {this.state.loading ?
                            <div className="spinner"></div>
                            :
                            <React.Fragment>
                                <div className="row mt-3">
                                    <Button className="d-inline " style={{ width: "15%" }} onClick={() => this.currentMonthTransactions()}>Mes Actual</Button>

                                    <Button variant="info" style={{ width: "15%", marginInline: "1%" }} onClick={() => this.showDates()}>Buscar Fechas</Button>

                                </div>
                                <div className="row mt-1">
                                    <Button variant="info" className="d-inline mt-3" style={{ width: "15%", height: "40px" }} onClick={() => this.lastMonthTransactions()}>Mes Anterior</Button>

                                    <div className="col-2" style={{ display: "flex", alignItems: "center", paddingRight: "10px" }}>
                                        {this.state.showRange ? <h6 style={{ marginRight: "10px", marginBottom: "0" }}>Desde: </h6> : <h6 style={{ marginRight: "10px", marginBottom: "0" }}>En la fecha: </h6>}
                                        <input
                                            type="date"
                                            id="start"
                                            name="startDate"
                                            required
                                            value={this.state.startDate}
                                            onChange={this.handleChange}
                                            style={{ marginBottom: "0" }}
                                        />
                                    </div>

                                    {this.state.showRange &&
                                        <div className="col-2" style={{ display: "flex", alignItems: "center", paddingRight: "10px" }}>
                                            <h6 style={{ marginRight: "10px", marginBottom: "0" }}>Hasta: </h6>
                                            <input
                                                type="date"
                                                id="end"
                                                name="endDate"
                                                required
                                                value={this.state.endDate}
                                                onChange={this.handleChange}
                                                style={{ marginBottom: "0" }}
                                            />
                                        </div>
                                    }

                                    {this.state.showRange &&
                                        <div className="col-2 d-flex align-items-center">
                                            <Button variant="outline-danger" style={{ marginRight: "5px" }} onClick={() => {
                                                this.setState({
                                                    showRange: false,
                                                    startDate: "",
                                                    endDate: ""
                                                });
                                            }}>Cancelar</Button>
                                            <Button variant="outline-primary" disabled={this.state.startDate.length === 0 || this.state.endDate.length === 0} onClick={() => this.dateTransactions()}>Buscar</Button>
                                        </div>
                                    }
                                </div>

                                <div className="row mt-1">
                                    <div className="col">
                                        <Button variant="warning" className="printButtonReport d-inline" onClick={() => this.handlePrint({})}>Imprimir reporte</Button>{' '}
                                        {/* <Button variant="warning" className="printButtonReport" onClick={() => this.handlePrint({})}>Enviar Correo</Button>{' '} */}
                                    </div>
                                </div>
                                {this.state.payHistory.length === 0 ?

                                    <Alert variant="secondary" className="infoAlert">
                                        No hay pagos en el rango de fechas ingresada
                                    </Alert> :

                                    <React.Fragment>
                                        <Table responsive className="custom-table" >
                                            <thead className="tr1">
                                                <tr >
                                                    <th scope="col">Nombre</th>
                                                    <th scope="col">Negocio</th>
                                                    <th scope="col">Merchant ID</th>
                                                    <th scope="col"># Factura</th>
                                                    <th scope="col">Fecha</th>
                                                    <th scope="col">Hora</th>
                                                    <th scope="col">Metodo de pago</th>
                                                    <th scope="col">Terminales</th>
                                                    <th scope="col">Total</th>
                                                    <th scope="col"># de referencia</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentPageData.map((pay, index) => (
                                                    <tr key={index}>
                                                        <td>{pay.username}</td>
                                                        <td>{pay.merchantName}</td>
                                                        <td>{pay.merchantID}</td>
                                                        <td>{pay.invoiceNumber}</td>
                                                        <td>{pay.date}</td>
                                                        <td>{pay.time}</td>
                                                        <td>{pay.paymentMethod}</td>
                                                        <td>{pay.terminals}</td>
                                                        <td>{pay.totalAmount}</td>
                                                        <td>{pay.referenceNumber}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>

                                        <ReactPaginate
                                            pageCount={pageC}
                                            onPageChange={this.handlePageChange}
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            forcePage={this.state.currentPage} // Establece la página actual activa en el componente de paginación
                                        />
                                    </React.Fragment>

                                }

                            </React.Fragment>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PayReport;