import React from "react";
import Navbar from "../componentes/Navbar";
import '../componentes/GlobalVariables'
import axios from "axios";
import '../elements/AdminDashboard.css'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import BarChart from '../componentes/BarChart';

/**
 * Componente inicial de la página del administrador
 * 
 * Se encarga de enseñar un resumen del conteo de los clientes registrados, terminales activos, clientes sin reportar en los 
 * últimos 15 días, clientes desactivados y clientes falta de pago.
 */
class AdminDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
            role: -1,
            infoDashboard: {},
            error: false
        };
    }

    /**
     * Metodo encargado de obtener la información a través de una petición hacia el back
     */
    async componentDidMount() {
        const token = localStorage.getItem('loggedIvuUser')
        const [header, payload, signature] = token.split('.');

        // Decodificar la carga útil (payload) que contiene la información del usuario
        const decodedPayload = JSON.parse(atob(payload));

        const role = decodedPayload['role']
        this.setState({ token: token, role: role })
        const config = {
            method: 'get',
            url: global.URLBack + `/dashBoard/superUser/homeInfo`,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            withCredentials: true
        }

        await axios(config)
            .then(res => {  
                this.setState({ infoDashboard: res.data })
            }).catch(err => {
                this.setState({ error: true })
                localStorage.removeItem('loggedIvuUser')
                window.location.reload()
                //localStorage.removeItem('role')
            })

        this.loadPendingPayments()
    }

    loadPendingPayments = async () => {
        try {
            const config = {
                method: 'get',
                url: global.URLBack + `/dashBoard/superUser/invoice/inProcess`,
                headers: { 'Authorization': 'Bearer ' + this.state.token },
                withCredentials: true
            };
    
            const res = await axios(config);
    
            this.setState({
                clientesPorAprobacion: res.data.length
            })
        } catch (error) {
            console.error("Error en la carga de pagos:", error);
        }
    };

    
    /**
     * Renderiza la barra de navegación basada en el estado actual.
     *
     * @return {ReactElement} El componente Navbar renderizado.
     */
    renderNavbar() {
        // Puedes acceder al estado actualizado aquí y pasar el valor a Navbar
        const { role } = this.state;
        if (Number(role) !== -1) {
            return <Navbar SupUser={role} />;
        }

    }

    // Método para redirigir
    redirectToRoot() {
        if (this.state.error) {
            // Redirige al usuario a la ruta raíz "/" si ocurre un error
            return <Redirect to="/" />;
        }
    }

    /**
     * Metodo de renderizado
     * @returns Página html que contiene las cards con los conteos
     */
    render() {
        return (
            <div id="nav">
                {this.redirectToRoot()} {/* Llama al método de redirección */}
                {this.renderNavbar()}

                <div className="card_layout">
                    <div className="row cont" >
                        <div className="col">
                            <div className="card card-admin" id="card-1" >
                                <Link to="/admin/users">
                                    <div className="card-details">
                                        <p className="text-title">Clientes Registrados</p>
                                        <p className="text-b">{this.state.infoDashboard.cantidadClientes}</p>
                                    </div>
                                    <button className="card-button">Más información</button>
                                </Link>
                            </div>
                        </div>
                        <div className="col"  >
                            <div className="card card-admin" id="card-2">
                                <Link to="/admin/usersTerminals">
                                    <div className="card-details">
                                        <p className="text-title">Terminales Activos</p>
                                        <p className="text-b">{this.state.infoDashboard.terminalesActivos}</p>
                                    </div>
                                    <button className="card-button">Más información</button>
                                </Link>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card card-admin" id="card-3">
                                <Link to="/admin/unreportedClients">
                                    <div className="card-details">
                                        <p className="text-title">Sin reportar en los últimos 15 dias</p>
                                        <p className="text-b">{this.state.infoDashboard.terminalesInactivos15dias}</p>
                                    </div>
                                    <button className="card-button">Más información</button>
                                </Link>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card card-admin" id="card-4">

                                <div className="card-details">
                                    <p className="text-title">Clientes Desactivados</p>
                                    <p className="text-b">{this.state.infoDashboard.clientesInactivos}</p>
                                </div>
                                <button className="card-button">Más información</button>

                            </div>
                        </div>
                        <div className="col">
                            <div className="card card-admin" id="card-5">
                                <Link to="/admin/noPayClients">
                                    <div className="card-details">
                                        <p className="text-title">Clientes Falta Pago</p>
                                        <p className="text-b">{this.state.infoDashboard.clientesFaltaPago}</p>
                                    </div>
                                    <button className="card-button">Más información</button>
                                </Link>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card card-admin" id="card-6" >
                                <Link to="/admin/payBill">
                                    <div className="card-details">
                                        <p className="text-title">Clientes Por Aprobación</p>
                                        <p className="text-b">{this.state.clientesPorAprobacion}</p>
                                    </div>
                                    <button className="card-button">Más información</button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {this.state.infoDashboard.usersCurrentMonthActivations ?
                        <div className="row">
                            <div className="col-md-6 mt-5 text-center">
                                <p>
                                <strong>TOTAL DE VENTAS MES ACTUAL: </strong>
                                <span>{new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }).format(this.state.infoDashboard?.totalSalesCurrentMonth || 0)}</span>
                                </p>
                            </div>
                            <div className="col-md-6 mt-5 text-center">
                                <p>
                                <strong>TOTAL DE VENTAS MES ANTERIOR: </strong>
                                <span>{new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }).format(this.state.infoDashboard?.totalSalesPreviousMonth || 0)}</span>
                                </p>
                            </div>
                            <BarChart 
                            currentMonthActivations={this.state.infoDashboard?.usersCurrentMonthActivations?.length}
                            previousMonthActivations={this.state.infoDashboard?.previousMonthActivations}
                            currentMonthRegistrations={this.state.infoDashboard?.usersCurrentMonthRegistrations?.length}
                            previousMonthRegistrations={this.state.infoDashboard?.previousMonthRegistrations}
                            usersCurrentMonthActivations={this.state.infoDashboard?.usersCurrentMonthActivations}
                            usersCurrentMonthRegistrations={this.state.infoDashboard?.usersCurrentMonthRegistrations}
                            />
                        </div>
                        :null
                    }
                    
                </div>

            </div>
        )
    }
}


export default AdminDashboard;