import React from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Navbar from "../componentes/Navbar";
import axios from "axios";
import { withRouter } from 'react-router-dom';
import Swal from "sweetalert2";
import Logo from "../img/logo.png";
import ReactPaginate from 'react-paginate';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import { right } from "@popperjs/core";

/**
 * Componente encargado de guardar las facturas de los pagos de comisiones de un revendedor
 */
class PayCommissions extends React.Component {

    /**
     * Inicializa el componente con las props proporcionadas.
     *
     * @param {Object} props - Las props pasadas al componente.
     */
    constructor(props) {
        super(props);
        this.state = {
            role: -1,
            token: "",
            currentPage: 0,
            perPage: 10, // Número de elementos por página
            pageCount: 0,
            loading: false,
            paymentMethods: [],
            reseller: {},
            payHistory: [],
            clientList: [],
            currentPage2: 0,
            perPage2: 5, // Número de elementos por página
            pageCount2: 0,
            checkedClients: [],
            invoice: {
                date: "",
                time: "",
                paymentMethod: "",
                total: 0,
                resellerSalesId: [],
                idReseller: -1
            }
        }
    }

    /**
     * Método llamado después de que el componente se haya montado en el DOM.
     * Obtiene el token de autenticación del usuario desde el almacenamiento local y lo decodifica.
     * Actualiza el estado del componente con el token y el rol del usuario.
     * Accede al valor del estado pasado en la propiedad `location`.
     * Realiza una solicitud para obtener cuentas impagas del revendedor.
     * Actualiza el estado del componente con los datos obtenidos de la respuesta.
     * Realiza una solicitud para obtener los métodos de pago disponibles para el usuario.
     * Actualiza el estado del componente con los métodos de pago obtenidos de la respuesta.
     *
     */
    componentDidMount() {
        const token = localStorage.getItem('loggedIvuUser')
        const [header, payload, signature] = token.split('.');

        // Decodificar la carga útil (payload) que contiene la información del usuario
        const decodedPayload = JSON.parse(atob(payload));

        const role = decodedPayload['role']
        this.setState({ token: token, role: role })

        // Accede al valor del estado pasado en la propiedad `location`
        const reseller = this.props.location.state.reseller;

        if (reseller != undefined) {
            this.setState((prevState) => ({
                invoice: { ...prevState.invoice, idReseller: reseller.id }
            }));
            const config2 = {
                method: 'get',
                url: global.URLBack + `/dashBoard/reseller/unpaidAccounts/${reseller.id}`,
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                withCredentials: true
            }

            axios(config2).then(response => {
                if (Number(response.status) === 200) {

                    var pageC = Math.ceil(response.data.unpaidAccounts.length / this.state.perPage2);
                    var pageC1 = Math.ceil(response.data.paymentHistory.length / this.state.perPage);
                    this.setState({ reseller: reseller, clientList: response.data.unpaidAccounts, payHistory: response.data.paymentHistory, pageCount: pageC1, pageCount2: pageC })
                }
            }).catch(err => {

            })
        } else {
            Swal.fire({
                title: "No fue posible cargar la información del revendedor intentelo más tarde",
                icon: "error"
            })
            this.props.history.push("/");
        }

        const config = {
            method: 'get',
            url: global.URLBack + `/dashBoard/superUser/paymentMethods`,
        }

        axios(config).then(response2 => {
            this.setState({ paymentMethods: response2.data })
        }).catch(error => {
        })




    }

    /**
     * Metodo utilizado para cambiar el valor de los parametros de invoice
     * @param {*} e evento que dispara el input
     */
    handleChange = (e) => {
        var { name, value } = e.target;

        this.setState((prevState) => ({
            invoice: { ...prevState.invoice, [name]: value }
        }));
    };

    /**
    * Metodo utilizado para imprimir una factura
    * @param {*} payDetails Información de la factura
    */
    handlePrint = (payDetails) => {
        const style = `
                
        /* Estilos para ocultar en la vista normal */
        .printable-content .print-only {
          display: none;
        }
        
        /* Estilos específicos para la vista de impresión */
        @media print {
          .printable-content .print-only {
            display: block;
          }
        
          .invoice-container {
            font-family: Arial, sans-serif;
            width: 80%;
            margin: 0 auto;
            padding: 20px;
            background-color: #ffffff;
            border: 1px solid #d3d3d3;
            border-radius: 10px;
          }
        
          .invoice-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 1px solid #d3d3d3;
            margin-bottom: 10px;
          }
        
          .invoice-date {
            text-align: right;
          }
        
          .invoice-details {
            margin-top: 20px;
          }
        
          .invoice-total {
            margin-top: 40px;
            text-align: center;
            padding-top: 20px;
            border-top: 1px solid #d3d3d3;
          }
        
          .customer-info,
          .address-info,
          .service-info,
          .software-info,
          .payment-info {
            padding: 10px;
            background-color: #f2f2f2;
            border-radius: 5px;
          }
        
          h1,
          h2,
          h3 {
            color: #007bff;
          }
        
          p {
            margin: 8px 0;
            font-size: larger;
          }
        
          body {
            font-family: Arial, sans-serif;
            font-size: 14px;
          }
        }
        
        

      `;

        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);

        const content = `
          <html>
            <head>
              <style>${style}</style>
            </head>
            <body>
              ${this.getPrintableContent(payDetails)}
            </body>
          </html>
        `;

        const doc = iframe.contentWindow.document;
        doc.open();
        doc.write(content);
        doc.close();

        // Iniciar la impresión
        iframe.contentWindow.print();
    };

    /**
     * Metodo utilizado para retornar el html que se muestra al imprimir una factura
     * @param {*} payDetails 
     * @returns 
     */
    getPrintableContent = (payDetails) => {
        const terminalInfo = payDetails.resellerSales.map((sales, index) => (
            `<div style="display: flex;
            justify-content: space-between;
            margin-bottom: 10px; width:100%">
                <p><strong>Cliente:</strong> ${sales.clientName !== undefined ? sales.clientName : ""}</p>
                <p style="float:right;margin-right:15%"><strong>Comision:</strong>$ ${Number(sales.commission)}</p>
            </div>
            `
        ))
        const formattedTerminalInfo = terminalInfo.join("");
        return `
        <div class="printable-content">
        <!-- Contenido que deseas imprimir -->
        <div className="print-only">
          <div className="invoice-container" style=" font-family: Arial, sans-serif;
          ">
            <div className="invoice-header" style=" display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 1px solid #d3d3d3;
            margin-bottom: 10px;">
              <img src="${Logo}" alt="Logo" width="200px" height="60px" />
              <div className="invoice-date" style=" text-align: right;">
                <p style="font-size: large;"><strong>Fecha:</strong> ${payDetails.date}</p>
                <p style="font-size: large;"><strong>Hora:</strong> ${payDetails.time}</p>
              </div>
            </div>
           
            <div className="customer-info" style=" 
            padding-left: 10px;
            background-color: #f2f2f2;
            border-radius: 5px;">
              <h3>Información del revendedor</h3>
              <p><strong>Nombre:</strong>${this.state.reseller.firstname + " " + this.state.reseller.lastname}</p>
              <p><strong>Nombre de Negocio:</strong> ${this.state.reseller.company}</p>
              <p><strong>Email:</strong> ${this.state.reseller.email1}</p>
              <p><strong>Telefono:</strong> ${this.state.reseller.phone}</p>
              <p><strong>Dirección :</strong> ${this.state.reseller.address}</p>
              <p><strong>Estado:</strong> ${this.state.reseller.status === true ? "ACTIVO" : "INACTIVO"}</p>
            </div>
            <div className="software-info"  style="  
            padding-left: 10px;
            background-color: #f2f2f2;
            border-radius: 5px;">
              <h3>Ventas</h3>
            
             ${formattedTerminalInfo}
            </div>
            <div className="payment-info"  style="  
            padding-left: 10px;
            padding-bottom: 10px;
            background-color: #f2f2f2;
            border-radius: 5px;">
              <h3>Información de Pago</h3>
              <p><strong>Id de la factura:</strong> ${payDetails.paymentId}</p>  
              <p><strong>Método de Pago:</strong> ${payDetails.paymentMethod}</p>  
              ${payDetails.paymentMethod === "CREDIT-CARD" ?
                `<p><strong>Número de referencia:</strong> ${payDetails.referenceNumber}</p>` : ` `
            }                 
            </div>
            <div className="invoice-total" style=" 
            text-align: center;
            padding-top: 20px;
            border-top: 1px solid #d3d3d3;">
              <h2><strong>Total Pagado</strong></h2>
             <h2 style="color:black"><strong>$ ${payDetails.total}</strong></h2>
            
            </div>
          </div>
        </div>
      </div>
      
        `;
    };

    /**
     * Renderiza la barra de navegación en función del estado actual del rol.
     *
     * @return El componente Navbar renderizado o null.
     */
    renderNavbar() {
        // Puedes acceder al estado actualizado aquí y pasar el valor a Navbar
        const { role } = this.state;

        if (Number(role) !== -1) {
            return <Navbar SupUser={role} />;
        }

    }

    /**
* Metodo utilizado para manejar la paginación de la tabla
* @param {*} param0 Pagina seleccionada
*/
    handlePageChange = ({ selected }) => {
        this.setState({
            currentPage: selected,
            selectedElement: null, // Reinicia el elemento seleccionado al cambiar de página
        });
    };

    /**
* Metodo utilizado para manejar la paginación de la tabla
* @param {*} param0 Pagina seleccionada
*/
    handlePageChange2 = ({ selected }) => {
        this.setState({
            currentPage2: selected,
            selectedElement2: null, // Reinicia el elemento seleccionado al cambiar de página
        });
    };

    setCheckedClients = (resellerSalesId, comissions) => {
        // Comprobar si el cliente ya está en la lista
        const clienteIndex = this.state.checkedClients.indexOf(Number(resellerSalesId));

        console.log(this.state.checkedClients);
        console.log(resellerSalesId);
        console.log(clienteIndex);

        if (clienteIndex === -1) {
            // Si no está en la lista, agregarlo y sumar las comisiones al total
            this.setState((prevState) => {
                const totalAmount = Number(prevState.invoice.total) + Number(comissions);
                return {
                    checkedClients: [...prevState.checkedClients, Number(resellerSalesId)],
                    invoice: { ...prevState.invoice, total: totalAmount }
                };
            });
        } else {
            // Si ya está en la lista, quitarlo y restar las comisiones del total
            this.setState((prevState) => {
                const nuevaLista = [...prevState.checkedClients];
                nuevaLista.splice(clienteIndex, 1);
                const totalAmount = Number(prevState.invoice.total) - Number(comissions);
                return {
                    checkedClients: nuevaLista,
                    invoice: { ...prevState.invoice, total: totalAmount }
                };
            });
        }

    }


    /**
     * Verifica si la factura actual es válida.
     *
     * @return {boolean} Devuelve true si la factura es válida, false en caso contrario.
     */
    isValid() {
        if (this.state.invoice.paymentMethod.length > 0 && this.state.invoice.date.length > 0 && this.state.checkedClients.length > 0) {
            return true
        } else {
            return false
        }
    }

    /**
     * Limpia el estado de la factura restableciendo todos los valores a su estado inicial.
     *
     */
    clean() {
        this.setState({
            invoice: {
                date: "",
                time: "",
                paymentMethod: "",
                total: 0,
                resellerSalesId: [],
                idReseller: -1
            }
        })
    }

    /**
     * Metodo encargado de guardar una factura
     */
    savePayment = () => {
        let fecha = this.state.invoice.date.split("T")[0]
        let hora = this.state.invoice.date.split("T")[1]
        this.setState((prevState) => ({
            invoice: { ...prevState.invoice, resellerSalesId: this.state.checkedClients, date: fecha, time: hora }
        }), () => {
            const config = {
                method: 'post',
                url: global.URLBack + `/dashBoard/reseller/payment`,
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                },
                withCredentials: true,
                data: this.state.invoice
            }
            axios(config).then(response => {
                if (Number(response.status) === 200) {
                    Swal.fire({
                        title: "Factura guardada correctamente",
                        icon: "success"
                    })
                    window.location.reload()
                }
            })
        });
    }

    /**
     * Renderiza el componente.
     *
     * @return El componente renderizado.
     */
    render() {

        const { reseller, currentPage, perPage, payHistory, clientList, currentPage2, perPage2 } = this.state
        const offset = currentPage * perPage;
        var pageC = this.state.pageCount
        var currentPageData = []
        if (payHistory.length > 0) {
            currentPageData = payHistory.slice(offset, offset + perPage);
        } else {
            currentPageData = payHistory
        }

        const offset2 = currentPage2 * perPage2;
        var pageC2 = this.state.pageCount2
        var currentPageData2 = []
        if (clientList.length > 0) {
            currentPageData2 = clientList.slice(offset2, offset2 + perPage2);
        } else {
            currentPageData2 = clientList
        }
        return (
            <React.Fragment>
                {this.renderNavbar()}
                <div className='mainn'>
                    <div className="sonn1" style={{ height: "50px" }}>
                        <p className='titulo'><strong>Pago de comisiones</strong></p>
                    </div>
                    <div className="sonn">
                        {this.state.loading ?
                            <div className="spinner"></div>
                            :
                            <React.Fragment>

                                {this.state.loading ?
                                    <div className="spinner"></div>
                                    :
                                    <React.Fragment>

                                        <div className="row">
                                            <div className="col">

                                                <h5>Nombre: {reseller.firstname + " " + reseller.lastname}</h5>


                                                <h5>Negocio: {reseller.company}</h5>
                                                <h5>Dirección: {reseller.address}</h5>
                                            </div>
                                            <div className="col">
                                                <h5>Telefono: {reseller.phone}</h5>
                                                <h5>Email: {reseller.email1}</h5>
                                            </div>

                                            <div className="col">
                                                <h5>Estado:  {reseller.status === true ? "ACTIVO" : "INACTIVO"}</h5>
                                            </div>
                                        </div>


                                    </React.Fragment>
                                }
                            </React.Fragment>

                        }
                    </div>
                    < div className="sonn1" style={{ height: "50px" }}>
                        <p className='titulo'>Procesar pago</p>
                    </div>
                    <div className="sonn">
                        <div className="row mt-3">
                            <div className="col-3">
                                <h5>Metodo de pago: </h5>
                                <Form.Select aria-label="Default select example" required name="paymentMethod" onChange={this.handleChange}>
                                    {this.state.paymentMethods.map((method, index) => (
                                        <option key={index} value={method.code}>{method.name}</option>
                                    ))
                                    }
                                    <option value="CASH">Efectivo</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" >
                                    Por favor seleccione un metodo de pago
                                </Form.Control.Feedback>

                            </div>
                            <div className="col-3 " style={{ marginLeft: "3%" }}>
                                <h5>Fecha de pago: </h5>
                                <input type="datetime-local" id="start" name="date" className="w-100" required onChange={this.handleChange}

                                />

                                {' '}<Button variant="primary mt-3 w-50" disabled={!this.isValid() || Number(this.state.role) === 3} onClick={() => this.savePayment()}>Guardar</Button>

                            </div>
                            <div className="col-5 " style={{ paddingLeft: "7%" }}>
                                {clientList.length > 0 ?
                                    <React.Fragment>
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Cliente</th>
                                                <th>Comisiones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentPageData2.map((client, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <input 
                                                            type="checkbox" 
                                                            onChange={() => this.setCheckedClients(client.resellerSalesId, client.commission)} 
                                                            checked={this.state.checkedClients.includes(Number(client.resellerSalesId))} // Sincronizar con el estado
                                                        />
                                                    </td>
                                                    <td>{client.clientName}</td>
                                                    <td>{client.commission}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <p style={{ float: 'right' }}>
                                        <strong>TOTAL: </strong>$ {this.state.invoice.total}
                                    </p>
                                    <ReactPaginate
                                        pageCount={pageC2}
                                        onPageChange={this.handlePageChange2}
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        forcePage={this.state.currentPage2} // Establece la página actual activa en el componente de paginación
                                    />
                                </React.Fragment>
                                
                                    :
                                    <Alert variant="secondary" className="infoAlert">
                                        No cuenta con clientes pendientes
                                    </Alert>
                                }


                            </div>
                        </div>
                        {this.state.payHistory.length > 0 ?
                            <React.Fragment>
                                <h4 className="mt-3">HISTORIAL DE PAGOS</h4>
                                <Table responsive className="custom-table" >
                                    <thead className="tr1">
                                        <tr >

                                            <th scope="col"># Factura</th>
                                            <th scope="col">Fecha</th>
                                            <th scope="col">Hora</th>
                                            <th scope="col">Metodo de pago</th>
                                            <th scope="col">Clientes</th>
                                            <th scope="col">Comisiones</th>
                                            <th scope="col">Total</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPageData.map((pay, index) => {
                                            var client = pay.resellerSales.map((elemento) => elemento.clientName)
                                            var clientConcatenadas = client.join('<br>')
                                            var commission = pay.resellerSales.map((elemento) => "$ " + elemento.commission)

                                            var commissionConcatenadas = commission.join('<br>')
                                            return (
                                                <tr key={index}>
                                                    <td>{pay.paymentId}</td>
                                                    <td>{pay.date}</td>
                                                    <td>{pay.time}</td>
                                                    <td>{pay.paymentMethod}</td>
                                                    <td dangerouslySetInnerHTML={{ __html: clientConcatenadas }}></td>
                                                    <td dangerouslySetInnerHTML={{ __html: commissionConcatenadas }}></td>
                                                    <td>$ {pay.total}</td>
                                                    <td>
                                                        <Button variant="outline-primary" onClick={() => this.handlePrint(pay)}>
                                                            Imprimir
                                                        </Button>
                                                    </td>

                                                </tr>)
                                        })}
                                    </tbody>
                                </Table>

                                <ReactPaginate
                                    pageCount={pageC}
                                    onPageChange={this.handlePageChange}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    forcePage={this.state.currentPage} // Establece la página actual activa en el componente de paginación
                                />


                            </React.Fragment> :
                            <Alert variant="secondary" className="infoAlert">
                                Aun no cuenta con un historial de pagos
                            </Alert>
                        }
                    </div>
                </div>
            </React.Fragment>
        )

    }
}
export default withRouter(PayCommissions);