import React from "react";
import Navbar from "../componentes/Navbar";
import Form from 'react-bootstrap/Form';
import '../elements/Table.css'
import '../elements/MonthActivity.css'
import Table from 'react-bootstrap/Table';
import axios from "axios";
import ReactPaginate from 'react-paginate';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Logo from "../img/logo.png";

/**
 * Componente utilizado para listar el historial de transacciones de más de 90 días de un cliente
 */
class TransactionHistory extends React.Component {

    /**
 * Atributo de estado que contiene los atributos variables
 */
    state = {
        token: '',
        role: -1,
        searchText: "",
        currentPage: 0,
        perPage: 10, // Número de elementos por página
        pageCount: 0,
        client: {},
        transactionsList: [],
        loading: false,
        searching: false,
        startDate: "",
        endDate: "",
        maxDate: "",
        users: [],
        searchQuery: '',
        searchResults: [],
        showOptions: false
    };

    /**
    * Referencia a el campo que contiene la información que ingresa el usuario de busqueda
    */
    searchRef = React.createRef()

    /**
     * Metodo encargado de obtener el token de usuario del localStorage y definirlo en el atributo de estado
     */
    async componentDidMount() {
        const token = localStorage.getItem('loggedIvuUser')
        const [header, payload, signature] = token.split('.');

        // Decodificar la carga útil (payload) que contiene la información del usuario
        const decodedPayload = JSON.parse(atob(payload));

        const role = decodedPayload['role']

        this.setState({ token: token, role: role })
        // Obtener la fecha actual
        let hoy = new Date();
        // Restar 90 días a la fecha actual
        hoy.setDate(hoy.getDate() - 90);
        // Obtener el día, el mes y el año de la fecha
        let dia = hoy.getDate();
        let mes = hoy.getMonth() + 1; // Los meses empiezan en 0
        let año = hoy.getFullYear();
        // Formatear la fecha como YYYY-MM-DD
        let fechaFormateada = `${año}-${mes < 10 ? "0" + mes : mes}-${dia < 10 ? "0" + dia : dia}`;
        this.setState({
            endDate: fechaFormateada,
            maxDate: fechaFormateada
        })
    }

    /**
    * Metodo utilizado para obtener el valor de la busqueda que ingresó el usuario a partir de la referencia creada
    * @param {*} event 
    */
    handleSearchChange = (event) => {
        this.setState({ searchText: this.searchRef.current.value });
    }

    /**
     * Metodo utilizado para manejar la paginación de la tabla
     * @param {*} param0 Página seleccionada
     */
    handlePageChange = ({ selected }) => {
        this.setState({
            currentPage: selected,
            selectedElement: null, // Reinicia el elemento seleccionado al cambiar de página
        });
    };

    /**
    * Metodo utilizado para capturar los cambios realizados y asignarlos a la variable del state correspondiente
    * @param {*} event input que lanzó el evento y que contiene el nombre y valor a cambiar
    */
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    /**
    * Metodo utilizado para listar posibles usuarios que coincidan con un criterio de busqueda ingresado por el usuario
    * para que posteriormente los seleccione y con ese usuario seleccionado se obtenga la información del cliente y su historial de pagos
    * @param {*} event Input que contiene el criterio de busqueda
    */
    handleInputChange = (event) => {
        const query = event.target.value;
        let showOptions = true
        var filteredUsers = []
        this.setState({ searchQuery: query });
        //TODO:AQUI SE COLOCA EL SERVICIO DEL BACKEND
        if (query.trim().length !== 0) {

            const config = {
                method: 'get',
                url: global.URLBack + `/dashBoard/superUser/usersFilter?filter=` + query,
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                },
                withCredentials: true
            }
            axios(config)
                .then(res => {
                    this.setState({ loading: false })
                    filteredUsers = res.data.forms
                    if (filteredUsers.length === 0 || query === "") {
                        showOptions = false
                    }
                    this.setState({ searchResults: filteredUsers, showOptions: showOptions });
                }).catch(err => {
                    this.setState({ loading: false })

                })


        } else {
            this.setState({ searchResults: [], showOptions: false });
        }

    };

    /**
    * Metodo utilizado para asignar cual fue el cliente seleccionado para obtener la información 
    * @param {*} option objeto del cliente seleccionado
    */
    handleSelectOption = (option) => {
        this.setState({ showOptions: false, searchText: option.merchantID, searchQuery: option.merchant_name });
    };

    /**
     * Metodo utilizado para realizar la busqueda de las transacciones de los ultimos 90 dias buscando a el usuario a partir de lo ingresado 
     * en el campo de busqueda
     */
    searchClient() {
        this.setState({ searching: true })
        console.log(global.URLBack + `/dashBoard/superUser/transactionsMore90Days?filtro=` + this.state.searchText + '&dateFrom=' + this.state.startDate + "&dateTo=" + this.state.endDate);
        console.log('entro aqui');
        
        const config = {
            method: 'get',
            url: global.URLBack + `/dashBoard/superUser/transactionsMore90Days?filtro=` + this.state.searchText + '&dateFrom=' + this.state.startDate +
                "&dateTo=" + this.state.endDate,
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
            withCredentials: true
        }
        this.setState({ loading: true })
        axios(config)
            .then(res => {
                this.setState({ loading: false })
                var pageC = Math.ceil(res.data.transacciones.length / this.state.perPage);
                this.setState({
                    client: res.data,
                    pageCount: pageC,
                    transactionsList: res.data.transacciones
                })
            }).catch(err => {
                this.setState({ client: {} })
                this.setState({ loading: false })
            })
    }

    /**
     * Metodo utilizado para imprimir el reporte
     */
    handlePrint = () => {

        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);

        const content = `
          <html>
            <head>
            </head>
            <body>
              ${this.getPrintableContent()}
            </body>
          </html>
        `;

        const doc = iframe.contentWindow.document;
        doc.open();
        doc.write(content);
        doc.close();

        // Iniciar la impresión
        iframe.contentWindow.print();
    };


    /**
     * Metodo utilizado para retornar el html que se muestra al imprimir un reporte
     * @returns html que se va a imprimir
     */
    getPrintableContent = () => {

        const { client, transactionsList } = this.state
        const transactions = transactionsList.map((transaction, index) => (
            `
            <tr key=${index}>
                <td>${transaction.recordId}</td>
                <td>${transaction.fecha}</td>
                <td>${transaction.Hora}</td>
                <td>${transaction.transaccionType}</td>
                <td>${transaction.amount}</td>
                <td>${transaction.municipal}</td>
                <td>${transaction.estatal10}</td>
                <td>${transaction.estatal6}</td>
                <td>${transaction.ivu}</td>
                <td>${transaction.machineID}</td>
                <td>${transaction.software}</td>
                <td>${transaction.createdOn}</td>
             </tr>
            `
        ))
        const formattedTransactions = transactions.join("");
        return `
       
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Reporte de Transacciones</title>
          <style>
          body {
            font-family: Arial, sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 0;
          }
      
          .report-container {
            background-color: #ffffff;
            border-radius: 8px;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            padding: 20px;
            margin: 20px auto;
          }
      
          .report-header {
            text-align: center;
            font-size: 24px;
            margin-bottom: 20px;
            color: #3498db;
          }
      
          .transaction-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #ccc;
            padding: 10px 0;
          }
      
          .transaction-label {
            color: #3498db;
            width: 150px;
          }
      
          .transaction-data {
            flex: 1;
          }
          .transaction-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
          }
      
          .transaction-table th,
          .transaction-table td {
            border: 1px solid #ccc;
            padding: 10px;
            text-align: left;
          }
      
          .transaction-label {
            color: #3498db;
            font-weight: bold;
          }
          </style>
        </head>
        <body>
        <div class="printable-content">
        <!-- Contenido que deseas imprimir -->
        <div className="print-only">
        <img src="${Logo}" alt="Logo" width="200px" height="60px" />
          <div class="report-container">

          
            <h1 class="report-header">Información del Cliente</h1>
            <div class="transaction-row">
                <span class="transaction-label">Nombre:</span>
                <span class="transaction-data">${client.username + " " + client.lastName + " " + client.secondLastName}</span>
            </div>
            <div class="transaction-row">
                <span class="transaction-label">Negocio:</span>
                <span class="transaction-data">${client.merchantName}</span>
            </div>
            <div class="transaction-row">
            <span class="transaction-label">Dirección:</span>
            <span class="transaction-data">${client.address}</span>
            <span class="transaction-label">Telefono:</span>
            <span class="transaction-data">${client.phone}</span>
            </div>
            <div class="transaction-row">
            <span class="transaction-label">Merchant ID:</span>
            <span class="transaction-data">${client.MerchantID}</span>
            <span class="transaction-label">Merchant #:</span>
            <span class="transaction-data">${client.merchantNumber}</span>
            </div>
            <div class="transaction-row">
            <span class="transaction-label">Ultima transacción:</span>
            <span class="transaction-data">${client.lastTransaction === undefined ? "" : client.lastTransaction}</span>
            <span class="transaction-label">Fecha de Expiración:</span>
            <span class="transaction-data">${client.expirationDate}</span>
            </div>
            <div class="transaction-row">
            <span class="transaction-label">Estado:</span>
            <span class="transaction-data">${client.status}</span>
            </div>

            <h1 class="report-header">Reporte de Transacciones</h1>
            <div class="transaction-row">
            <span class="transaction-label">Fecha Desde </span>
            <span class="transaction-data">${this.state.startDate}</span>
            <span class="transaction-label">Fecha Hasta </span>
            <span class="transaction-data">${this.state.endDate}</span>
            </div>
            <table class="transaction-table">
            <tr>
              <th class="transaction-label">Record ID</th>
              <th class="transaction-label">Fecha</th>
              <th class="transaction-label">Hora</th>
              <th class="transaction-label">Transacción</th>
              <th class="transaction-label">Amount</th>
              <th class="transaction-label">Municipal (1%)</th>
              <th class="transaction-label">Estatal (10.5%)	</th>
              <th class="transaction-label">Estatal (6%)</th>
              <th class="transaction-label">IVU	</th>
              <th class="transaction-label">Machine ID</th>
              <th class="transaction-label">Software</th>
              <th class="transaction-label">Fecha de transacción</th>
            </tr>
           ${formattedTransactions}
          </table>
        
          </div>
          
        </div>
        </div>
        </body>
        </html>
        
      
        `;
    };

    renderNavbar() {
        // Puedes acceder al estado actualizado aquí y pasar el valor a Navbar
        const { role } = this.state;
        if (Number(role) !== -1) {
            return <Navbar SupUser={role} />;
        }

    }

    /**
   * Renderiza el componente.
   *
   * @return  El componente renderizado.
   */
    render() {
        const { currentPage, perPage, transactionsList, searchQuery, searchResults, showOptions } = this.state;
        const offset = currentPage * perPage;
        var pageC = this.state.pageCount
        var currentPageData = []
        if (transactionsList.length > 0) {
            currentPageData = transactionsList.slice(offset, offset + perPage);
        } else {
            currentPageData = transactionsList
        }

        return (
            <React.Fragment>
                {this.renderNavbar()}
                <div className='mainn'>
                    <div className="sonn1">
                        <p className='titulo'><strong>Historial de transacciones</strong></p>
                    </div>
                    <div className="sonn">
                        {this.state.loading ?
                            <div className="spinner"></div>
                            :
                            <React.Fragment>
                                <Alert variant="info" className="infoAlert">
                                    Recuerde que puede realizar la busqueda ingresando el nombre del cliente, nombre del negocio, el ID del merchant o el número del merchant.
                                    <br></br>{' '}
                                    <strong>Las transacciones desplegadas serán de más de 90 días de vigencia</strong>

                                </Alert>
                                <div className="row">


                                    <div className="row " style={{ width: "55%", marginTop: "10px" }}>
                                        <div className="col-2">
                                            <h4 className="mt-3" >
                                                Nombre:
                                            </h4>
                                        </div>
                                        <div className="col-9">

                                            <Form.Group className="mt-3" >
                                                <Form.Control
                                                    type="text"
                                                    value={searchQuery}
                                                    onChange={this.handleInputChange}
                                                    placeholder="Escribe y selecciona una opción..."
                                                />
                                                {showOptions && searchResults.length > 0 && (
                                                    <ul className="autocomplete-options">
                                                        {searchResults.map((result, index) => (
                                                            <li
                                                                key={index}
                                                                onClick={() => this.handleSelectOption(result)}
                                                                className="autocomplete-option"
                                                            >
                                                                <div><strong>
                                                                    {result.merchantID}
                                                                </strong>
                                                                </div>
                                                                <div><strong>Nombre: </strong>
                                                                    {result.username + " " + result.lastName + " " + result.secondLastName}
                                                                </div>
                                                                <div><strong>Negocio: </strong>
                                                                    {result.merchant_name}
                                                                </div>

                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </Form.Group>
                                        </div>
                                    </div>


                                    {/*  <Form>
                                            <Form.Group className="mb-3" id="ControlInput1">
                                                <Form.Label
                                                    className="mb-3"
                                                >   Nombre:</Form.Label>
                                                <Form.Control type="text" placeholder="Ingrese su busqueda" value={this.state.searchText} ref={this.searchRef} onChange={this.handleSearchChange} />
                                            </Form.Group>
                                        </Form> */}
                                    <div className="col-2">
                                        <h6>Desde: </h6>
                                        <input type="date" id="start" name="startDate" required
                                            value={this.state.startDate} max={this.state.endDate} onChange={this.handleChange}
                                        />
                                        <br></br>
                                        {' '}

                                    </div>
                                    <div className="col-2">
                                        <h6>Hasta: </h6>
                                        <input type="date" id="start" name="endDate" required
                                            value={this.state.endDate} max={this.state.maxDate} onChange={this.handleChange}
                                        />
                                        <br></br>
                                        {' '}

                                    </div>
                                    <div className="col mt-4" >
                                        <Button className="btnSearch mt-1" variant="primary" onClick={this.searchClient.bind(this)} disabled={this.state.searchText.trim().length === 0 || this.state.startDate.length === 0 || this.state.endDate.length === 0}>Buscar</Button>
                                    </div>
                                </div>
                                {(Object.keys(this.state.client).length <= 1 && this.state.searching === false) || (Object.keys(this.state.client).length > 1 && this.state.searching === true) ?
                                    <React.Fragment>


                                        <div className="row mt-3">
                                            <div className="col">
                                                {this.state.client.username !== undefined ?
                                                    <h5>Nombre: {this.state.client.username + " " + this.state.client.lastName + " " + this.state.client.secondLastName}</h5>
                                                    : <h5>Nombre:</h5>
                                                }
                                                <h5>Negocio: {this.state.client.merchantName}</h5>
                                                <h5>Dirección: {this.state.client.address}</h5>
                                            </div>
                                            <div className="col">
                                                <h5>Telefono:  {this.state.client.phone}</h5>
                                                <h5>Merchant ID:  {this.state.client.MerchantID}</h5>
                                                <h5>Merchant #:  {this.state.client.merchantNumber}</h5>
                                            </div>
                                            <div className="col">
                                                <h5>Ultima transacción:  {this.state.client.lastTransaction}</h5>
                                                <h5>Fecha Expiración:  {this.state.client.expirationDate}</h5>
                                            </div>
                                            <div className="col">
                                                <h5>Estado:  {this.state.client.status}</h5>
                                            </div>
                                        </div>
                                        {transactionsList !== undefined && (transactionsList.length > 0 || this.state.searching === true) ?
                                            <div className="row">
                                                <div className="col">
                                                    <Button variant="warning" className="printButtonReport" onClick={() => this.handlePrint()}>Imprimir reporte</Button>{' '}
                                                </div>

                                            </div> : null
                                        }
                                        <div className="row">
                                            {transactionsList !== undefined && (transactionsList.length > 0 || this.state.searching === false) ?
                                                <React.Fragment>
                                                    <Table responsive >
                                                        <thead className="tr1">
                                                            <tr>

                                                                <th scope="col">Record ID</th>
                                                                <th scope="col">Fecha</th>
                                                                <th scope="col">Hora</th>
                                                                <th scope="col">Transacción</th>
                                                                <th scope="col">Amount</th>
                                                                <th scope="col">Municipal (1%)</th>
                                                                <th scope="col">Estatal (10.5%)</th>
                                                                <th scope="col">Estatal (6%)</th>
                                                                <th scope="col">IVU</th>
                                                                <th scope="col">Machine ID</th>
                                                                <th scope="col">Software</th>
                                                                <th scope="col">Fecha de transacción</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {currentPageData.map((transaction, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{transaction.recordId}</td>
                                                                        <td>{transaction.fecha}</td>
                                                                        <td>{transaction.Hora}</td>
                                                                        <td>{transaction.transaccionType}</td>
                                                                        <td>{transaction.amount}</td>
                                                                        <td>{transaction.municipal}</td>
                                                                        <td>{transaction.estatal10}</td>
                                                                        <td>{transaction.estatal6}</td>
                                                                        <td>{transaction.ivu}</td>
                                                                        <td>{transaction.machineID}</td>
                                                                        <td>{transaction.software}</td>
                                                                        <td>{transaction.createdOn}</td>
                                                                    </tr>
                                                                )
                                                            })

                                                            }
                                                        </tbody>
                                                    </Table>
                                                    <ReactPaginate
                                                        pageCount={pageC}
                                                        onPageChange={this.handlePageChange}
                                                        containerClassName="pagination"
                                                        activeClassName="active"
                                                        forcePage={this.state.currentPage} // Establece la página actual activa en el componente de paginación
                                                    />
                                                </React.Fragment> : null
                                            }
                                            {transactionsList.length <= 0 && this.state.searching ?

                                                <Alert variant="secondary" className="infoAlert">
                                                    No cuenta con actividad en el rango de fechas ingresada
                                                </Alert> : null

                                            }
                                        </div>
                                    </React.Fragment> : null
                                }
                                {Object.keys(this.state.client).length <= 1 && this.state.searching === true ?
                                    <Alert variant="warning" className="infoAlert">
                                        Su criterio de busqueda no coincidió con ningun registro
                                    </Alert> : null
                                }
                            </React.Fragment>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default TransactionHistory;