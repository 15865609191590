import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from "sweetalert2";
import axios from "axios";
import InputMask from "react-input-mask";
import InputGroup from 'react-bootstrap/InputGroup';
import '../elements/RegisterReseller.css'
import Image from 'react-bootstrap/Image';
import Logo from "../img/logo.png";
import { Link } from 'react-router-dom';

/**
 * Componente encargado de registrar a un revendedor
 */
class RegisterReseller extends React.Component {
    /**
     * Constructor para el componente.
     *
     * @param props - los props del componente.
     */
    constructor(props) {
        super(props);
        this.state = {
            role: -1,
            token: "",
            show: false,
            loading: false,
            reseller: {
                firstname: "",
                lastname: "",
                username: "",
                password: "",
                company: " ",
                phone: "",
                address: "",
                imageprofile: "",
                status: true,
                id: 0,
                commissionsBalance: 0,
                email1: "",
                email2: undefined
            },
            image: "",
            initial: props.reseller,
            validated: false,
            validForm: true,
            edit: props.edit,
            logo: ""
        }
    }

    /**
     * Actualiza el componente después de haber sido actualizado con nuevas props.
     *
     * @param prevProps - El objeto de props anterior.
     * @return {void} Esta función no devuelve nada.
     */
    componentDidUpdate(prevProps) {
        // Verificar si los props relevantes han cambiado
        if (this.props.reseller !== prevProps.reseller) {
            this.setState({ initial: this.props.reseller })
        }
    }

    /**
     * La función componentDidMount es un método del ciclo de vida que se invoca inmediatamente después de que se monta un componente.
     * Se utiliza para realizar cualquier configuración necesaria, como la obtención de datos, la suscripción a eventos o la inicialización del estado.
     * 
     * Esta función realiza lo siguiente:
     * 1. Recupera el token del almacenamiento local.
     * 2. Decodifica la carga útil (payload) del token.
     * 3. Recupera el rol de la carga útil decodificada y lo establece en el estado del componente.
     * 4. Si el estado 'edit' es verdadero, realiza una solicitud GET para recuperar una imagen de perfil.
     * 5. Crea un objeto Blob a partir de los datos de respuesta y genera una URL para la imagen.
     * 6. Establece el estado 'reseller' en el estado inicial y establece el estado 'logo' en la URL de la imagen generada.
     * 7. Si hay un error en la solicitud GET, establece el estado 'reseller' en el estado inicial y establece el estado 'logo' en un logotipo predeterminado.
     *
     * @return {void} Esta función no devuelve ningún valor.
     */
    componentDidMount() {
        const token = localStorage.getItem('loggedIvuUser')
        const [header, payload, signature] = token.split('.');

        // Decodificar la carga útil (payload) que contiene la información del usuario
        const decodedPayload = JSON.parse(atob(payload));

        const role = decodedPayload['role']
        this.setState({ token: token, role: role })
        if (this.state.edit) {
            const config = {
                method: 'get',
                url: global.URLBack + `/dashBoard/reseller/imageprofile/${this.state.initial.imageprofile}`,
                headers: {
                    'Authorization': 'Bearer ' + this.state.token,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
                responseType: 'arraybuffer',
            }

            axios(config).then(response => {

                // Crear un objeto Blob a partir de los bytes de la imagen
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                // Crear una URL que apunte al Blob
                const imageUrl = URL.createObjectURL(blob);

                this.setState({
                    reseller: this.state.initial,
                    logo: imageUrl
                })
            }).catch(error => {
                this.setState({
                    reseller: this.state.initial,
                    logo: Logo
                })
            })


        }
    }

    /**
* Metodo utilizado para desplegar el modal
*/
    handleShow = () => {
        this.setState({ show: true })

    };

    /**
* Metodo utilizado para ocultar el modal
*/
    handleClose = () => {
        if (this.state.edit) {
            this.setState({ show: false, reseller: this.state.initial, validated: false, validForm: true })
        } else {
            this.setState({ show: false, validated: false, validForm: true })
            this.reset()
        }
    };

    /**
     * Restablece el estado del reseller.
     *
     */
    reset() {
        this.setState({
            reseller: {
                firstname: "",
                lastname: "",
                username: "",
                password: "",
                company: " ",
                phone: "",
                address: "",
                imageprofile: "",
                status: true,
                id: 0,
                commissionsBalance: 0,
                email1: "",
                email2: undefined
            }
        })
    }

    /**
 * Metodo utilizado para validar el formulario 
 * @param {*} e Elemento que disparó el evento
 * @returns True si el formulario se encuentra correcto, False en caso contrario.
 */
    valdidateForm = (e) => {
        const form = document.getElementById("formUser");
        this.setState({
            validated: true
        })
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();

            this.setState({
                validForm: false
            })
            return false
        } else {

            this.setState({
                validForm: true
            })
            return true
        }
    }

    /**
     * Metodo que actualiza el valor de los atributos del estado de acuerdo a lo ingresado en el formulario
     * @param {*} e evento del input
     */
    handleChange = (e) => {
        var { name } = e.target;
        let value = e.target.type === "checkbox" ? e.target.checked : e.target.value

        if (name === "idRole") {
            value = Number(value)
        }
        if (name === "email2") {
            if (value.length === 0) {
                value = null
            }
        }

        this.setState((prevState) => ({
            reseller: { ...prevState.reseller, [name]: value }
        }));

        if (name !== "status") {
            this.valdidateForm(e)
        }

    };

    /**
     * Metodo encargado de guardar el revendedor
     * @param {*} e evento submit disparado por el formulario
     */
    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!this.state.edit) {
            const formData = new FormData();

            formData.append("image", this.state.image);
            let type = this.state.image.name.split(".")[1]

            const files = {
                method: "post",
                url:
                    global.URLBack +
                    `/dashBoard/reseller/imageprofile/` + type,          //AQUI  
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': 'Bearer ' + this.state.token,
                },
                data: formData,
                withCredentials: true,
            };
            this.setState({ loading: true })
            axios(files).then(res => {
                if (Number(res.status) === 200) {
                    this.setState((prevState) => ({
                        reseller: { ...prevState.reseller, imageprofile: res.data.nameFile }
                    }), () => {
                        const config1 = {
                            method: 'post',
                            url: global.URLBack + `/dashBoard/reseller`,
                            headers: {
                                'Authorization': 'Bearer ' + this.state.token,
                                "Content-Type": "application/json",
                            },
                            data: this.state.reseller,
                            withCredentials: true
                        }

                        axios(config1).then(response => {
                            this.setState({ loading: false })

                            if (Number(response.status) === 201) {
                                Swal.fire({
                                    title: "Revendedor creado exitosamente",
                                    icon: "success"
                                })
                                this.props.updateList(response.data)
                                this.handleClose()

                            }

                        }).catch((err) => {
                            this.setState({ loading: false })

                            Swal.fire({
                                title: "Ha ocurrido un error al crear el revendedor",
                                icon: "error"
                            })
                        })

                    })
                }
            }).catch((err) => {
                this.setState({ loading: false })
                Swal.fire({
                    title: "Ha ocurrido un error al guardar la imagen el revendedor",
                    icon: "error"
                })
            })



        } else {

            const config = {
                method: 'put',
                url: global.URLBack + `/dashBoard/reseller/` + this.state.reseller.id,
                headers: {
                    'Authorization': 'Bearer ' + this.state.token,
                    "Content-Type": "application/json",
                },
                data: this.state.reseller,
                withCredentials: true
            }
            this.setState({ loading: true })
            axios(config).then(response => {
                this.setState({ loading: false })
                if (response) {
                    Swal.fire({
                        title: "Revendedor editado exitosamente",
                        icon: "success"
                    })
                    this.props.updateList(response.data)
                    this.handleClose()
                } else {
                    Swal.fire({
                        title: "Ha ocurrido un error al editar el revendedor",
                        icon: "error"
                    })
                }
            }).catch((err) => {

                this.setState({ loading: false })
                Swal.fire({
                    title: "Ha ocurrido un error al editar el revendedor",
                    icon: "error"
                })
            })
        }



    }

    /**
     * Metodo encargado de guardar en una variable la imagen que se sube del reseller
     * @param {*} e evento onChange disparado por el input
     */
    handleOnImageChange = (e) => {
        let files = e.target.files;

        this.setState({
            image: files[0]
        })

        /*    this.setState((prevState) => ({
               reseller: { ...prevState.reseller, imageprofile: files[0] }
           })); */
    }


    validarYActualizarEstado = () => {
        return new Promise(resolve => {
            const reseller = { ...this.state };

            for (const key in reseller) {
                if (reseller.hasOwnProperty(key) && reseller[key].length <= 0) {
                    reseller[key] = null;
                }
            }

            // Actualizar el estado con los cambios
            this.setState(reseller, () => {
                resolve(); // Resuelve la promesa después de actualizar el estado
            });
        });
    };

    /**
   * Renderiza el componente.
   *
   * @return  El componente renderizado.
   */
    render() {
        const { show, edit, validated, reseller, role } = this.state
        let typeInput = "password"
        if (edit) {
            typeInput = "text"
        }
        return (
            <React.Fragment>
                {edit ?
                    <div className="editButton" style={{ cursor: "pointer" }} onClick={this.handleShow} >   <svg
                        viewBox="0 0 24 24"
                        width="25"
                        height="25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="m3.99 16.854-1.314 3.504a.75.75 0 0 0 .966.965l3.503-1.314a3 3 0 0 0 1.068-.687L18.36 9.175s-.354-1.061-1.414-2.122c-1.06-1.06-2.122-1.414-2.122-1.414L4.677 15.786a3 3 0 0 0-.687 1.068zm12.249-12.63 1.383-1.383c.248-.248.579-.406.925-.348.487.08 1.232.322 1.934 1.025.703.703.945 1.447 1.025 1.934.058.346-.1.677-.348.925L19.774 7.76s-.353-1.06-1.414-2.12c-1.06-1.062-2.121-1.415-2.121-1.415z"
                            fill="#000000"
                        />
                    </svg></div> :
                    <Button variant="success" onClick={this.handleShow} className="mt-3"> <h5>Nuevo Revendedor</h5></Button>
                }
                <Modal
                    show={show}
                    onHide={this.handleClose}
                >
                    <Modal.Header closeButton>


                        {!edit ?
                            <React.Fragment>
                                <div className="row w-100">
                                    <Modal.Title id="example-custom-modal-styling-title" className="mod-title" >
                                        Registrar revendedor
                                    </Modal.Title>
                                </div>
                                {edit ?
                                    <div className="row  w-100">
                                        <img id="logo-reseller" alt="logo" src={this.state.logo}></img >
                                    </div> :
                                    null
                                }


                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className="row w-100">
                                    <Modal.Title id="example-custom-modal-styling-title" className="mod-title" >
                                        Editar revendedor
                                    </Modal.Title>
                                </div>
                                {edit ?
                                    <div className="row  w-100">
                                        <img id="logo-reseller" alt="logo" src={this.state.logo}></img >
                                    </div> :
                                    null
                                }

                            </React.Fragment>
                        }

                    </Modal.Header>
                    <Modal.Body>
                        {this.state.loading &&
                            <div className="overlay">
                                <div className="spinner2"></div>
                            </div>
                        }
                        <Form noValidate className="form-style" id="formUser" validated={validated} >
                            <Form.Group className="mb-3" >
                                <Form.Label>Nombre *</Form.Label>
                                <Form.Control type="text" value={reseller.firstname} required name="firstname" onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3} />
                                <Form.Control.Feedback type="invalid" >
                                    Por favor ingrese el nombre
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Apellido *</Form.Label>
                                <Form.Control type="text" value={reseller.lastname} required name="lastname" onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3} />
                                <Form.Control.Feedback type="invalid" >
                                    Por favor ingrese el apellido
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Usuario *</Form.Label>
                                <Form.Control type="text" value={reseller.username} pattern="[^@]*" required name="username" onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3} />
                                <Form.Control.Feedback type="invalid" >
                                    Por favor ingrese el usuario valido
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Contraseña *</Form.Label>
                                <Form.Control type={typeInput} value={reseller.password} required name="password" pattern="^.{8,12}$" onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3} />
                                <Form.Control.Feedback type="invalid" >
                                    Por favor ingrese la contraseña del usuario.
                                    La contraseña debe ser de 8 a 12 digitos
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Compañia *</Form.Label>
                                <Form.Control type="text" value={reseller.company} required name="company" onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3} />
                                <Form.Control.Feedback type="invalid" >
                                    Por favor ingrese la compañia
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Telefono *</Form.Label>
                                <InputMask
                                    className="inputMask"
                                    value={reseller.phone}
                                    onChange={this.handleChange}
                                    name="phone"
                                    type="text"
                                    mask="999-999-9999"
                                    placeholder="Número de teléfono"
                                    readOnly={Number(role) === 3} disabled={Number(role) === 3}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    Por favor ingrese la compañia
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Label>Dirección *</Form.Label>
                                <Form.Control type="textarea" value={reseller.address} required name="address" onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3} />
                                <Form.Control.Feedback type="invalid" >
                                    Por favor ingrese la dirección
                                </Form.Control.Feedback>
                            </Form.Group>

                            {edit ?
                                <div className="row">
                                    <div className="col">
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>Balance de referidos</InputGroup.Text>
                                            <InputGroup.Text>$</InputGroup.Text>
                                            <Form.Control aria-label="Dollar amount (with dot and two decimal places)" value={reseller.commissionsBalance} readOnly disabled name="commissionsBalance" onChange={this.handleChange} />
                                        </InputGroup>
                                    </div>
                                    <div className="col">
                                        <Link to={{
                                            pathname: '/admin/payCommissions',
                                            state: { reseller: reseller }
                                        }}>
                                            <Button variant="primary">Hacer Pago</Button>
                                        </Link>
                                    </div>
                                    <div className="col pl-0" >
                                        <Link to={`/admin/resellerPayReport/${reseller.id}`}>
                                            <Button variant="warning">
                                                <svg
                                                    height="30px"
                                                    width="30px"
                                                    viewBox="0 0 48 48"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <rect width="48" height="48" fill="white" fillOpacity="0.01" />
                                                    <rect
                                                        x="13"
                                                        y="10"
                                                        width="28"
                                                        height="34"
                                                        fill="#2F88FF"
                                                        stroke="#000000"
                                                        strokeWidth="4"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M35 10V4H8C7.44772 4 7 4.44772 7 5V38H13"
                                                        stroke="#000000"
                                                        strokeWidth="4"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M21 22H33"
                                                        stroke="white"
                                                        strokeWidth="4"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M21 30H33"
                                                        stroke="white"
                                                        strokeWidth="4"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                                <h6> Historial de Pagos </h6>

                                            </Button>
                                        </Link>
                                    </div>
                                </div> :
                                <Form.Group className="mb-3" >
                                    <Form.Label>Logo *</Form.Label>
                                    <Form.Control type="file" id="file1" placeholder="Seleccionar archivo" required accept="image/*" onChange={this.handleOnImageChange} readOnly={Number(role) === 3} disabled={Number(role) === 3} />
                                    <Form.Control.Feedback type="invalid" >
                                        Por favor ingrese el logo
                                    </Form.Control.Feedback>
                                </Form.Group>
                            }

                            <div className="row" style={{ alignItems: "center" }}>
                                <div className="col-2" >
                                    <h6>Estado: </h6>
                                </div>
                                <div className="col">
                                    <label className="switch">
                                        <input className="chk" type="checkbox" checked={reseller.status} name="status" onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3} />
                                        <span className="slider"></span>
                                    </label>
                                </div>


                            </div>
                            <br></br>
                            <small>Los email serán enviados cada vez que algún cliente se registra bajo su cuenta</small>
                            <Form.Group className="mb-3" >
                                <Form.Label>Email 1 *</Form.Label>
                                <Form.Control type="email" value={reseller.email1} pattern="^(?!\s*$).+" required name="email1" onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3} />
                                <Form.Control.Feedback type="invalid" >
                                    Por favor ingrese el correo
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Email 2</Form.Label>
                                <Form.Control type="email" value={reseller.email2} pattern="^(?!\s*$).+" name="email2" onChange={this.handleChange} readOnly={Number(role) === 3} disabled={Number(role) === 3} />
                                <Form.Control.Feedback type="invalid" >
                                    Por favor ingrese el correo
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleClose}>Cancelar</Button>
                        {Number(role) !== 3 ?
                            <Button variant="success" onClick={this.handleSubmit} disabled={!this.state.validForm} >Guardar cambios</Button>
                            : null
                        }
                    </Modal.Footer>
                </Modal >
            </React.Fragment>
        )
    }

}

export default RegisterReseller;