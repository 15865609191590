import React from "react";
import Navbar from "../componentes/Navbar";
import axios from "axios";
import '../elements/Table.css'
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ReactPaginate from 'react-paginate';

/**
 * Componente utilizado para listar los clientes junto con sus terminales
 */
class UsersTerminals extends React.Component {
    /**
    * Atributo de estado que contiene los atributos variables
    */
    state = {
        token: '',
        role: -1,
        userTerminals: [],
        searchText: "",
        currentPage: 0,
        perPage: 10, // Número de elementos por página
        pageCount: 0,
        loading: false
    };

    /**
    * Referencia a el campo que contiene la información que ingresa el usuario de busqueda
    */
    searchRef = React.createRef()

    /**
     * Componente encargado de obtener el token de usuario del localStorage y definirlo en el atributo de estado y obtener la lista de clientes y terminales
     */
    async componentDidMount() {
        const token = localStorage.getItem('loggedIvuUser')
        const [header, payload, signature] = token.split('.');

        // Decodificar la carga útil (payload) que contiene la información del usuario
        const decodedPayload = JSON.parse(atob(payload));

        const role = decodedPayload['role']
        this.setState({ token: token, role: role })
        const config = {
            method: 'get',
            url: global.URLBack + `/dashBoard/superUser/usersTerminales`,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            withCredentials: true
        }
        this.setState({ loading: true })
        await axios(config)
            .then(res => {
                this.setState({ loading: false })
                var pageC = Math.ceil(res.data.length / this.state.perPage);
                this.setState({
                    userTerminals: res.data,
                    pageCount: pageC
                })

            }).catch(err => {
                this.setState({ loading: false })
                localStorage.removeItem('loggedIvuUser')
                //localStorage.removeItem('role')
            })

    }

    /**
     * Metodo utilizado para manejar la paginación de la tabla
     * @param {*} param0 Pagina seleccionada
     */
    handlePageChange = ({ selected }) => {
        this.setState({
            currentPage: selected,
            selectedElement: null, // Reinicia el elemento seleccionado al cambiar de página
        });
    };

    /**
     * Metodo utilizado para obtener el valor de la busqueda que ingresó el usuario a partir de la referencia creada
     * @param {*} event 
     */
    handleSearchChange = (event) => {
        this.setState({ searchText: this.searchRef.current.value });
    }

    /**
     * Renderiza el componente Navbar en función del estado actual del rol.
     *
     * @return  El componente Navbar renderizado.
     */
    renderNavbar() {
        // Puedes acceder al estado actualizado aquí y pasar el valor a Navbar
        const { role } = this.state;
        if (Number(role) !== -1) {
            return <Navbar SupUser={role} />;
        }

    }


    /**
     * Metodo de renderizado
     * @returns Html que contienen el elemento de busqueda para filtrar clientes y la tabla de clientes y terminales
     */
    render() {
        // Aplicar el filtro
        const filteredTerminals = this.state.userTerminals.filter(terminal =>
            Object.values(terminal).some(value =>
                String(value).toLowerCase().includes(this.state.searchText.toLowerCase())
            )
        );

        const { currentPage, perPage, userTerminals } = this.state;
        const offset = currentPage * perPage;
        var pageC = this.state.pageCount
        var currentPageData;
        if (this.state.searchText !== "") {

            currentPageData = filteredTerminals.slice(offset, offset + perPage);
            pageC = Math.ceil(filteredTerminals.length / this.state.perPage)
        } else {
            currentPageData = userTerminals.slice(offset, offset + perPage);
        }




        return (
            <React.Fragment>
                {this.renderNavbar()}
                <div className='mainn'>
                    <div className="sonn1">
                        <p className='titulo'><strong>Clientes y Terminales</strong></p>
                    </div>
                    <div className="sonn">

                        {this.state.loading ?
                            <div className="spinner"></div>
                            :
                            <React.Fragment>

                                <Row className="g-2 search-bar" >
                                    <Col >
                                        <p className="search-label"><strong>Buscar: </strong></p>
                                    </Col>
                                    <Col style={{ maxWidth: "500px" }} >
                                        <Form>
                                            <Form.Group className="mb-3" id="ControlInput1">
                                                <Form.Label
                                                    className="mb-3"
                                                >    </Form.Label>
                                                <Form.Control type="text" placeholder="Ingrese su busqueda" ref={this.searchRef} onChange={this.handleSearchChange} />
                                            </Form.Group>
                                        </Form>

                                    </Col>
                                </Row>
                                <Table className="table">
                                    <thead className="tr1">
                                        <tr>

                                            <th scope="col">Nombre</th>
                                            <th scope="col">Negocio</th>
                                            <th scope="col">Dirección</th>
                                            <th scope="col">Teléfono</th>
                                            <th scope="col">Registro de comerciante</th>
                                            <th scope="col">Número de terminales</th>
                                            <th scope="col">Ultima transacción</th>
                                            <th scope="col">#'s de seriales de terminales</th>
                                            <th scope="col">Software</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            currentPageData.map((userT, i) => {
                                                var ultimasTransacciones = userT.terminales.map((elemento) => elemento.ultimaTransaccion)
                                                var transaccionesConcatenadas = ultimasTransacciones.join('<br>')
                                                var seriales = userT.terminales.map((elemento) => elemento.terminalNumber)
                                                var serialesConcatenadas = seriales.join('<br>')
                                                var software = userT.terminales.map((elemento) => elemento.software)
                                                var softwareConcatenadas = software.join('<br>')
                                                return (
                                                    <tr key={i}>
                                                        <td>{userT.username}</td>
                                                        <td>{userT.merchantName}</td>
                                                        <td>{userT.address}</td>
                                                        <td >{userT.phone}</td>
                                                        <td >{userT.merchantNumber}</td>
                                                        {userT.terminales.length > 0 ?
                                                            <React.Fragment>
                                                                <td>{userT.terminales.length}</td>
                                                                <td dangerouslySetInnerHTML={{ __html: transaccionesConcatenadas }}></td>
                                                                <td dangerouslySetInnerHTML={{ __html: serialesConcatenadas }}></td>
                                                                <td dangerouslySetInnerHTML={{ __html: softwareConcatenadas }}></td>
                                                            </React.Fragment> :
                                                            <React.Fragment>
                                                                <td></td><td></td><td></td><td></td>
                                                            </React.Fragment>
                                                        }

                                                    </tr>
                                                )
                                            })

                                        }

                                    </tbody>
                                </Table>
                                <ReactPaginate
                                    pageCount={pageC}
                                    onPageChange={this.handlePageChange}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    forcePage={this.state.currentPage} // Establece la página actual activa en el componente de paginación
                                />
                            </React.Fragment>
                        }
                    </div>
                </div>
            </React.Fragment>

        )
    }
}

export default UsersTerminals;