import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import React from "react";
import Button from 'react-bootstrap/Button';
import '../elements/UserDashboard.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CheckChange from './CheckChange';
import axios from "axios";
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';

/**
 * Componente que representa el modal para realizar la edición de un negocio
 */
class EditMerchant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditModal: false,
            validated: false,
            merchantID: props.merchantEdit,
            validForm: true,
            merchant: {
                merchant_Name: "",
                newMerchantnumber: "",
                address: "",
                address2: "",
                fiscalterminals: 1,
                id_add: 0,
                id_form: 0,
                id_merch: 0,
                merchantnumber: "",
                mobilephone: "",
                phone: "",
                software: "",
                zipcode: "",
                tokenUser: "",
                iduser: 0,
                dateCV: undefined,
                idregister: "",
                idtown: 0
            },
            modalHeight: 0,
            softwareList: [],
            towns: [],
            idUser: 0,
            token: "",
            loading: false,
            loading2: false,
            services: []
        };
    }

    /**
     * Metodo utilizado para cargar información necesaria como ciudades, softwares y el token del usuario
     */
    async componentDidMount() {
        const token = localStorage.getItem('loggedIvuUser')
        this.setState({ token: token })

        const config2 = {
            method: "get",
            url: global.URLBack + `/query/register/loadData/3`,
        };
        await axios(config2)
            .then(response => {
                this.setState({
                    towns: response.data.towns,
                    softwareList: response.data.software
                })
            })
    }

    /**
     * Metodo utilizado para validar el formulario de edición del usuario
     * @param {*} e Elemento que disparó el evento
     * @returns True si el formulario se encuentra correcto, False en caso contrario.
     */
    valdidateForm = (e) => {
        const form = document.getElementById("formEdit");
        this.setState({
            validated: true
        })
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({
                validForm: false
            })
            return false
        } else {
            this.setState({
                validForm: true
            })
            return true
        }
    }

    /**
     * Metodo que actualiza el valor de los atributos del estado de acuerdo a lo ingresado en el formulario
     * @param {*} e evento input
     */
    handleChangeEditModal = (e) => {
        var { name, value } = e.target;
        this.setState((prevState) => ({
            merchant: { ...prevState.merchant, [name]: value }
        }));

        this.valdidateForm(e)
    };

    handleChangeService = (e) => {
        var { name, value } = e.target;
        this.setState((prevState) => ({
            merchant2: { ...prevState.merchant2, [name]: value }
        }));

        this.valdidateForm(e)
    };

    handleChangeRecurringPayments = (e) => {
        var { name } = e.target;
        let value = e.target.type === "checkbox" ? e.target.checked : e.target.value
        this.setState((prevState)=>({
            infoToken: { ...prevState.infoToken, [name]: value }
        }));

        this.valdidateForm(e)
    };

    /**
     * Metodo encargado de desplegar u ocultar el modal y cargar la información del negocio
     * @param {*} value Si es True entonces se despliega el modal, en caso contrario se oculta
     */
    editModal = async (value) => {
        if (value) {
            if (this.state.merchantID.length > 0) {
                this.setState({ loading: true })
                const config = {
                    method: 'get',
                    url: global.URLBack + `/dashBoard/business/` + this.state.merchantID,
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token
                    },
                    withCredentials: true
                }
                await axios(config).then(res => {
                    this.setState({ loading: false })
                    this.setState({
                        merchant: res.data
                    }, () => {
                        let fiscalterminalsAux = 1
                        if (res.data.fiscalterminals !== 0) {
                            fiscalterminalsAux = res.data.fiscalterminals
                        }
                        this.setState((prevState) => ({
                            merchant: { ...prevState.merchant, newMerchantnumber: this.state.merchant.merchantnumber, fiscalterminals: fiscalterminalsAux }
                        }));
                    })
                }).catch(err => {
                    this.setState({ loading: false })
                    Swal.fire({
                        title: "Ha ocurrido un error cargando la información del negocio, por favor intentelo más tarde",
                        icon: "error"
                    })
                    this.setState({
                        showEditModal: value
                    })
                })


                const configBusinessService = {
                    method: 'get',
                    url: global.URLBack + `/dashBoard/business/service/` + this.state.merchantID,
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token
                    },
                    withCredentials: true
                }

                await axios(configBusinessService).then(res => {
                    this.setState({ loading: false })
                    this.setState({
                        merchant2: res.data
                    })
                }).catch(err => {
                    this.setState({ loading: false })
                    Swal.fire({
                        title: "Ha ocurrido un error cargando la información del negocio, por favor intentelo más tarde",
                        icon: "error"
                    })
                    this.setState({
                        showEditModal: value
                    })
                })

                const configService = {
                    method: "get",
                    url: global.URLBack + `/dashBoard/service`,
                };
        
                await axios(configService)
                .then(response => {
                    this.setState({
                        services: response.data,
                    })
                })

                const configExistToken = {
                    method: 'get',
                    url: global.URLBack + `/dashBoard/business/${this.state.merchantID}/token/exist`,
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token
                    },
                    withCredentials: true
                }

                await axios(configExistToken)
                .then(response => {
                    this.setState({
                        existToken: response.data,
                    })

                    if (response.data === true) {
                        const configToken = {
                            method: 'get',
                            url: global.URLBack + `/dashBoard/business/${this.state.merchantID}/token`,
                            headers: {
                                'Authorization': 'Bearer ' + this.state.token
                            },
                            withCredentials: true
                        }

                        axios(configToken)
                        .then(response => {
                            this.setState({
                                infoToken: response.data,
                            })
                        })
                    }
                })
            }
        }
        this.setState({
            showEditModal: value,
            validated: false
        })
    }

    /*     handleModalEntered = () => {
            // Una vez que el modal se ha mostrado, podemos obtener su altura
            const modalElement = this.modal;
    
            if (modalElement) {
                const modalHeight = modalElement.clientHeight;
                this.setState({ modalHeight });
            }
        } */

    /**
     * Metodo utilizado para realizar la edición del negocio
     */
    async editar() {

        const copiaObjeto = { ...this.state.merchant };
        copiaObjeto.paymentInProcess = undefined
        delete copiaObjeto.tokenUser;
        let config = {
            method: 'put',
            url: global.URLBack + `/dashBoard/business/` + this.state.merchant.merchantnumber,
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
            withCredentials: true,
            data: copiaObjeto
        }
        this.setState({ loading2: true })
        axios(config)
        .then(res => {
            this.setState({ loading2: false })
            if (res) {
                let services = Array.isArray(this.state.services) ? this.state.services : [];
                let service = services.find(service => service.serviceName == this.state.merchant2.service);

                if (service) {
                    this.updateChangeService(service)
                }

                if (this.state.existToken) {
                    this.updateChangeRecurringPayment()
                }

                Swal.fire({
                    title: "Negocio editado exitosamente",
                    icon: "success"
                }).then(()=>{
                    this.props.updateInfo(true)
                })
            }
            this.editModal(false)
        }).catch(err => {
            this.setState({ loading2: false })
            Swal.fire({
                title: "Ocurrio un error al editar el negocio",
                icon: "error"
            })
            this.editModal(false)
            this.props.updateInfo(false)
        })

    }

    async updateChangeService(service){
        let configUpdateService = {
            method: 'put',
            url: global.URLBack + `/dashBoard/business/${this.state.merchant.merchantnumber}/changeService/${service.serviceId}`,
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
            withCredentials: true
        }

        await axios(configUpdateService)
    }   
    
    async updateChangeRecurringPayment(){
        let configUpdateRecurringPayments = {
            method: 'put',
            url: global.URLBack + `/dashBoard/business/${this.state.merchant.merchantnumber}/recurringPayments/${this.state.infoToken.usingAutomaticPayment}`,
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            },
            withCredentials: true
        }

        await axios(configUpdateRecurringPayments)

    }


    /**
     * Metodo utilizado para realizar la edición de un negocio verificando que el formulario se encuentre correcto
     * @param {*} event evento submit
     */
    handleSubmitEditModal = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (this.state.merchant.merchantnumber !== this.state.merchant.newMerchantnumber) {
                const newMN = this.state.merchant.merchantnumber
                const oldMN = this.state.merchant.newMerchantnumber
                this.setState((prevState) => ({
                    merchant: { ...prevState.merchant, newMerchantnumber: newMN, merchantnumber: oldMN, idtown: Number(this.state.merchant.idtown) }
                }), () => {
                    if (this.state.merchant.dateCV.length === undefined || this.state.merchant.dateCV.length === 0) {
                        this.setState((prevState) => ({
                            merchant: { ...prevState.merchant, dateCV: null }
                        }), () => {
                            this.editar()
                        });
                    } else {
                        this.editar()
                    }
                });

            } else {
                if (this.state.merchant.dateCV.length === undefined || this.state.merchant.dateCV.length === 0) {
                    this.setState((prevState) => ({
                        merchant: { ...prevState.merchant, dateCV: null }
                    }), () => {
                        this.editar()
                    });
                } else {
                    this.editar()
                }
            }


        }

        this.setState({ validated: true })
    };

    /**
    * Metodo utilizado para descargar el formulario MC de un cliente
    * @param {*} tokenuser identificador del address codificado 
    */
    handleDownloadMC(tokenuser) {

        window.open(global.URLBack + `/dashBoard/business/downloadFormMC/` + tokenuser, "_blank")
    }

    /**
    * Metodo utilizado para descargar el formulario SC de un cliente
    * @param {*} tokenuser identificador del address codificado 
    */
    handleDownloadSC(tokenuser) {

        window.open(global.URLBack + `/dashBoard/business/downloadFormSC/` + tokenuser, "_blank")
    }

    /**
     * Metodo utilizado para actualizar el formato MC
     * @param {*} files archivo 
     */
    updateMC(files) {
        Swal.fire({
            title: "Si desea modificar el registro de comerciante se estará validando la modificación y posteriormente se cambiará en el sistema",
            icon: "warning",
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: "Cancelar",
            reverseButtons: true
        }).then((response) => {
            if (response.isConfirmed) {
                const formData = new FormData();
                formData.append("pdf", files[0]);
                let urlService = "/dashBoard/business/saveFormMC/"
                let verb = "Put"
                const config = {
                    method: verb,
                    url:
                        global.URLBack +
                        urlService +
                        this.state.merchant.id_add,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": "Bearer " + this.state.token
                    },
                    data: formData,
                    withCredentials: true
                };

                this.setState({ loading2: true })
                axios(config)
                    .then((res) => {
                        this.setState({ loading2: false })
                        if (res.status === 200) {
                            this.setState((prevState) => ({
                                merchant: { ...prevState.merchant, id_merch: res.data.id_merch }
                            }), () => {
                                Swal.fire(
                                    {
                                        title: res.data.msg,
                                        icon: 'success'
                                    }
                                )
                            })

                        }
                    })
                    .catch((err) => {
                        this.setState({ loading2: false })
                        Swal.fire(
                            {
                                title: 'Ocurrio un error al guardar el archivo, intentelo nuevamente.',
                                text: 'Recuerde que el archivo no puede ser muy pesado',
                                icon: 'error'
                            }
                        )
                    });
            }
        })

    }

    /**
  * Metodo utilizado para cargar los formularios MS o SC
  * @param {*} e input que activó el evento y contiene la información de los archivos
  */
    handleFiles = (e) => {


        const { name, files } = e.target;

        const formData = new FormData();
        let urlService = ""
        let verb = "post"
        let updateMC = false

        if (name === "mcLoad") {
            urlService = "/dashBoard/business/saveFormMC/"
            formData.append("pdf", files[0]);
            if (this.state.merchant.id_merch !== 0) {
                this.updateMC(files)
                updateMC = true
            }

        }
        if (name === "scLoad") {
            urlService = "/dashBoard/business/saveFormSC/"
            formData.append("pdf", files[0]);
            if (this.state.merchant.id_form !== 0) {
                verb = "put"
            }
        }

        if (!updateMC) {
            const config = {
                method: verb,
                url:
                    global.URLBack +
                    urlService +
                    this.state.merchant.id_add,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + this.state.token
                },
                data: formData,
                withCredentials: true
            };
            this.setState({ loading2: true })
            axios(config)
                .then((res) => {
                    this.setState({ loading2: false })
                    if (res.status === 200) {
                        if (name === "mcLoad") {
                            this.setState((prevState) => ({
                                merchant: { ...prevState.merchant, id_merch: res.data.id_merch }
                            }), () => {
                                Swal.fire(
                                    {
                                        title: res.data.msg,
                                        icon: 'success'
                                    }
                                )
                            })
                        }
                        if (name === "scLoad") {
                            this.setState((prevState) => ({
                                merchant: { ...prevState.merchant, id_form: res.data.id_form }
                            }),
                                () => {
                                    Swal.fire(
                                        {
                                            title: res.data.msg,
                                            icon: 'success'
                                        }
                                    )
                                });
                        }
                        Swal.fire(
                            {
                                title: res.data.msg,
                                icon: 'success'
                            }
                        )

                    }
                })
                .catch((err) => {
                    this.setState({ loading2: false })
                    /*  if (name === "mcLoad") {
                         this.setState((prevState) => ({
                             merchant: { ...prevState.merchant, id_merch: 0 }
                         }));
                     }
                     if (name === "scLoad") {
                         this.setState((prevState) => ({
                             merchant: { ...prevState.merchant, id_form: 0 }
                         }));
                     } */
                    Swal.fire(
                        {
                            title: 'Ocurrio un error al guardar el archivo, intentelo nuevamente.',
                            text: 'Recuerde que el archivo no puede ser muy pesado',
                            icon: 'error'
                        }
                    )
                });

        }

    }

    /**
     * Actualiza la fecha en la cual cargó un cheque void
     * @param {*} result resultado de la operación de realizar el cambio de cuenta. Es True si el cambio de cuenta fue exitoso o False en caso contrario.
     */
    updateVoidDate = (result) => {
        if (result) {
            // Obtenemos la fecha actual
            const currentDate = new Date();

            // Formateamos la fecha en "yyyy-mm-dd"
            const year = currentDate.getFullYear();
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const day = currentDate.getDate().toString().padStart(2, '0');

            const formattedDate = `${year}-${month}-${day}`;

            this.setState((prevState) => ({
                merchant: { ...prevState.merchant, dateCV: formattedDate }
            }));

        }
    }

    /**
     * Metodo de renderizado
     * @returns html que contiene el botón que despliega el modal y el modal
     */
    render() {
        const { merchant, merchant2, existToken, infoToken } = this.state 
        return (
            <React.Fragment>
                <Button className="btnEditar" onClick={async () => await this.editModal(true)}><strong>EDITAR</strong></Button>
                <Modal show={this.state.showEditModal} onHide={() => this.editModal(false)} dialogClassName="my-modal" >
                    {this.state.loading ?
                        <div className="spinner"></div>
                        :
                        <Form noValidate validated={this.state.validated} id="formEdit" onSubmit={this.handleSubmitEditModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Editar Negocio</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {this.state.loading2 &&
                                    <div className="overlay">
                                        <div className="spinner2"></div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-6">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Negocio *</Form.Label>
                                            <Form.Control type="text" value={merchant.merchant_Name} required name="merchant_Name" onChange={this.handleChangeEditModal} />
                                            <Form.Control.Feedback type="invalid" >
                                                Por favor ingrese el nombre del negocio
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Numero de registro del comerciante *</Form.Label>
                                            <Form.Control type="text" value={merchant.merchantnumber} required name="merchantnumber" pattern="^\d{11}$" readOnly />
                                            <Form.Control.Feedback type="invalid" >
                                                Por favor ingrese el numero de registro del comerciante valido
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Telefono Movil *</Form.Label>
                                            {/* <Form.Control type="text" value={merchant.mobilephone} required name="mobilephone" onChange={this.handleChangeEditModal} /> */}
                                            <InputMask
                                                onChange={this.handleChangeEditModal}
                                                className="form-control"
                                                id="cel"
                                                type="text"
                                                name="mobilephone"
                                                mask="999-999-9999"
                                                placeholder="Ingrese telefono movil"
                                                value={merchant.mobilephone}
                                                required
                                            ></InputMask>
                                            <Form.Control.Feedback type="invalid" >
                                                Por favor ingrese el telefono movil
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Telefono *</Form.Label>
                                            {/*  <Form.Control type="text" value={merchant.phone} required name="phone" pattern="^(?!\s*$).+" maxLength={15} onChange={this.handleChangeEditModal} /> */}
                                            <InputMask className="form-control"
                                                onChange={this.handleChangeEditModal}
                                                id="phone"
                                                name="phone"
                                                type="text"
                                                mask="999-999-9999"
                                                placeholder="Número de teléfono"
                                                value={merchant.phone}
                                                required
                                            ></InputMask>
                                            <Form.Control.Feedback type="invalid" >
                                                Por favor ingrese el telefono
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <div className="checkbox-container">

                                            <div className="checkbox-item">
                                                {merchant !== undefined && (merchant.dateCV !== undefined && merchant.dateCV !== null && typeof merchant.dateCV === 'string' && merchant.dateCV.length !== 0) ?
                                                    <React.Fragment>
                                                        <span><strong>CHEQUE VOID</strong></span>
                                                        <p>Fecha de envio: <br></br> {merchant.dateCV}</p>
                                                        {/* <Button variant="primary" className='mt-1'>Cambiar De Cuenta</Button> */}
                                                        <CheckChange merchantName={merchant.merchant_Name} merchantId={merchant.merchantnumber} updateVoidDate={this.updateVoidDate}></CheckChange>
                                                    </React.Fragment>
                                                    : null
                                                }

                                            </div>

                                            <div className="checkbox-item">
                                                <label className="container-check">
                                                    <input type="checkbox" readOnly name='mc' checked={merchant.id_merch !== 0} />
                                                    <div className="checkmark"></div>
                                                </label>
                                                <span><br></br><strong>REGISTRO DE COMERCIANTE</strong></span>
                                                <Button variant="primary" className='mt-1'> <label >Subir Archivo
                                                    <input className="fales"
                                                        type="file"
                                                        id="file1"
                                                        name="mcLoad"
                                                        placeholder="Cargar"
                                                        accept="application/pdf"
                                                        onChange={(e) => this.handleFiles(e)}
                                                        required={merchant.id_merch === 0}
                                                    />
                                                    <Form.Control.Feedback type="invalid" >
                                                        Por favor ingrese el archivo
                                                    </Form.Control.Feedback>
                                                </label>
                                                </Button>

                                                {merchant.id_merch !== 0 &&
                                                    <Button variant="warning" className='mt-1' onClick={() => this.handleDownloadMC(merchant.tokenUser)}>Visualizar</Button>
                                                }


                                            </div>

                                            <div className="checkbox-item">
                                                <label className="container-check">
                                                    <input type="checkbox" readOnly name='sc' checked={merchant.id_form !== 0} />
                                                    <div className="checkmark"></div>
                                                </label>
                                                <span><br></br><strong>Formulario SC2916</strong></span>
                                                <Button variant="primary" className='mt-1'>  <label>Subir Archivo

                                                    <input className="fales"
                                                        type="file"
                                                        id="file1"
                                                        name="scLoad"
                                                        placeholder="Cargar"
                                                        accept="application/pdf"
                                                        onChange={(e) => this.handleFiles(e)}
                                                    />
                                                </label>
                                                </Button>
                                                {merchant.id_form !== 0 &&
                                                    <Button variant="warning" className='mt-1' onClick={() => this.handleDownloadSC(merchant.tokenUser)}>Visualizar</Button>
                                                }
                                            </div>
                                        </div>

                                        <p className='mt-1'>Si desea actualizar la información presione "Subir Archivo" </p>

                                    </div>
                                    <div className="col-6">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Dirección *</Form.Label>
                                            <Form.Control type="text" value={merchant.address} required name="address" pattern="^(?!\s*$).+" onChange={this.handleChangeEditModal} />
                                            <Form.Control.Feedback type="invalid" >
                                                Por favor ingrese la dirección
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" >
                                            <Form.Control type="text" value={merchant.address2} name="address2" pattern="^(?!\s*$).+" onChange={this.handleChangeEditModal} />
                                        </Form.Group>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="idtown">
                                                <Form.Label>Ciudad</Form.Label>
                                                <Form.Select required name="idtown" value={merchant.idtown} onChange={this.handleChangeEditModal}>
                                                    {

                                                        this.state.towns.map((town, indexT) => {
                                                            return (
                                                                <option key={indexT} value={Number(town.id)}>{town.value}</option>
                                                            )
                                                        })

                                                    }

                                                </Form.Select>

                                            </Form.Group>

                                            <Form.Group as={Col} controlId="state">
                                                <Form.Label>Estado</Form.Label>
                                                <Form.Control disabled value={merchant.state === undefined || merchant.state.length === 0 ? "PR" : merchant.state} name="state" />
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="zipcode">
                                                <Form.Label>Zip</Form.Label>
                                                <Form.Control type="text" required name="zipcode" value={merchant.zipcode} maxLength={5} onChange={this.handleChangeEditModal} />
                                                <Form.Control.Feedback type="invalid" >
                                                    Por favor ingrese el zip, recuerde que es de 5 digitos
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="software">
                                                <Form.Label>Programa</Form.Label>
                                                <Form.Select name="software" value={merchant.software} onChange={this.handleChangeEditModal}>
                                                    <option value={""}>Seleccione un programa</option>
                                                    {this.state.softwareList
                                                        .map((software, index) => (
                                                            <option key={index} value={software.value}>
                                                                {software.value}
                                                            </option>
                                                        ))
                                                    }

                                                </Form.Select>

                                            </Form.Group>
                                            <Form.Group as={Col} controlId="fiscalterminals">
                                                <Form.Label>Terminales</Form.Label>
                                                <Form.Control type="number" required readOnly name="fiscalterminals" value={merchant.fiscalterminals} onChange={this.handleChangeEditModal} />
                                            </Form.Group>

                                           
                                        </Row>
                                        { merchant2? 
                                            <Row>
                                                <Form.Group as={Col} controlId="service">
                                                    <Form.Label>Servicio</Form.Label>
                                                    <Form.Select name="service" value={merchant2.service} onChange={this.handleChangeService}>
                                                        <option value={""}>Seleccione un servicio</option>
                                                        {this.state.services
                                                            .map((service, index) => (
                                                                <option key={index} value={service.serviceName}>
                                                                    {service.serviceName}
                                                                </option>
                                                            ))
                                                        }

                                                    </Form.Select>

                                                </Form.Group>
                                            </Row> : null
                                        }
                                        { existToken && infoToken? 
                                            <Row>
                                                <Form.Group className="my-3" >
                                                    <Form.Label>Pago recurrente</Form.Label>
                                                    <label className="switch-2">
                                                        <input type="checkbox" class="toggle" checked={infoToken.usingAutomaticPayment} name="usingAutomaticPayment" onChange={this.handleChangeRecurringPayments}></input>
                                                        <span class="slider-2"></span>
                                                        <span class="card-side"></span>
                                                    </label>
                                                    <Form.Control.Feedback type="invalid" >
                                                        Por favor ingrese el estado del servicio
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row> : null
                                        }
                                        <p style={{ marginTop: "100px" }}>(CERTIFICADO DE COMPRAS EXCENTAS Y DE SERVICIOS SUJETOS AL IVU-ESPECIAL DE 4%) Por favor
                                            <a href="https://ivucontrolpr.com/images/Formulario%20SC_2916_2015_1.pdf" target="_blank" rel="noreferrer"> <u>Descargar</u></a>, completar, firmar y cargar.</p>
                                    </div>
                                </div>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.editModal(false)}>
                                    Cancelar
                                </Button>
                                <Button variant="primary" type="submit">
                                    Guardar
                                </Button>
                            </Modal.Footer>
                        </Form>
                    }
                </Modal>
            </React.Fragment>
        )
    }

}

export default EditMerchant;